import { generateConfigPayload, isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI004O/utils';
import { getLoggedUserClaims } from 'utils';
import { platformManaged, RI003, selectedEnv as selectedEnvConst } from './constant';

const getADGroupOwnerId = (order) => {
    let ownerId = '';
    if (order?.nonPrivilegedADGroupOwnerDetails) {
        ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid ? order?.nonPrivilegedADGroupOwnerDetails?.gpid : order?.nonPrivilegedADGroupOwnerDetails?.id;
    } else if (order?.privilegedADGroupOwnerDetails) {
        ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
    }
    return ownerId;
};
export const getPreviewPayload = ({ order }) => {
    const components = order?.resourceMgmt?.[selectedEnvConst]?.components;
    return {
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
        'application-service': order?.appProfile,
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'manager-id': order?.appOwnerDetails?.gpid,
        'business-owner-id': order?.businessContactDetails?.gpid,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        'budget-code': order?.budgetCode,
        'data-classification': order?.dataClassification,
        'site-name': order?.siteName,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(order),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),

        'environment-configurations': [
            {
                environment: order?.environment,
                ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-groups': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
                ...(order?.environment?.toLowerCase() !== 'production' &&
                    order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-groups': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
                components: generateConfigPayload(components),
            },
        ],
    };
};

export const getCostCalcPayload = ({ order, selectedEnv }) => {
    const components = order?.resourceMgmt?.[selectedEnv]?.components;
    return {
        // isExternal: order?.isExternal === 'Yes',
        catalogServiceCode: order?.catalogCode,
        components: generateConfigPayload(components),
        environment: order?.environment,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { applicationRegion: order?.region }),
        platformManaged,
    };
};

export const getOrderPayload = ({ order }) => {
    const components = order?.resourceMgmt?.[selectedEnvConst]?.components;

    return {
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
        'application-service': order?.appProfile,
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'manager-id': order?.appOwnerDetails?.gpid,
        'business-owner-id': order?.businessContactDetails?.gpid,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        'budget-code': order?.budgetCode,
        'data-classification': order?.dataClassification,
        'site-name': order?.siteName,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(order),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),

        'environment-configurations': [
            {
                environment: order?.environment,
                ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-groups': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
                ...(order?.environment?.toLowerCase() !== 'production' &&
                    order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-groups': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
                components: generateConfigPayload(components),
            },
        ],
    };
};

export const getResubmitOrderPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;

    const environmentDetails = catalog?.environmentConfigurations[0];
    const components = environmentDetails?.components;

    return {
        ...(catalog?.projectId && { 'project-id': catalog?.projectId }),
        ...(catalog?.businessUnit && { 'business-unit': catalog?.businessUnit }),
        sector: catalog?.sector,
        ...(['cgf']?.includes(catalog?.sector?.toLowerCase()) && { division: catalog?.division }),
        ...(isShowAppRegionField({ order: { sector: catalog?.sector, catalogCode: order?.catalogCode } }) && { 'application-region': catalog?.region }),
        'budget-code': catalog?.costCenter,
        ...(catalog?.billingInformation && { 'billing-information': catalog?.billingInformation }),

        'data-classification': catalog?.dataClassification,
        'site-name': catalog?.siteName,
        'manager-id': order?.manager?.id,
        'application-service': catalog?.applicationService,
        'application-name': catalog?.applicationName,
        'app-short-name': catalog?.applicationShortName,
        'business-owner-id': catalog?.businessOwnerId,
        ...(catalog?.readerAdGroup && catalog?.readerAdGroup?.length && { 'reader-ad-group': catalog?.readerAdGroup }),
        ...(catalog?.contributorAdGroup && catalog?.contributorAdGroup?.length && { 'contributor-ad-group': catalog?.contributorAdGroup }),
        ...(order?.activeDirectoryGroupOwner && { 'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id }),
        'environment-configurations': [
            {
                environment: environmentDetails?.environmentDisplayName,
                ...(environmentDetails?.contributorAdGroups &&
                    environmentDetails?.contributorAdGroups?.length && {
                        'contributor-ad-groups': environmentDetails?.contributorAdGroups?.map((z) => z),
                    }),
                ...(environmentDetails?.environmentDisplayName?.toLowerCase() !== 'production' &&
                    environmentDetails?.readerAdGroups &&
                    environmentDetails?.readerAdGroups?.length && { 'reader-ad-groups': environmentDetails?.readerAdGroups?.map((z) => z) }),
                components: components.map((component) => ({
                    ...component,
                    program: component.program === 'Global Shared' ? 'Not Applicable' : component.program,
                })),
            },
        ],
    };
};

const getKafkaTopics = (topics) => {
    const getConsumerGrps = (grps) => {
        const res = grps?.map((grp) => grp?.value).toString();
        return res;
    };
    return topics?.map((topic) => ({
        topicName: topic?.topicName,
        consumerGroup: getConsumerGrps(topic?.consumerGroups),
        isTransactional: topic?.isTransactionalId === 'Yes',
        transactionPrefixText: topic?.transactionprefixtext || null,
        partitionCount: topic?.partitions || null,
        retentionUnit: topic?.retention?.retentionUnit || null,
        retentionPeriod: topic?.retention?.retentionDetails?.millisec || null,
        producerId: topic?.writeAccessUser || null,
        consumerId: topic?.readAccessUser || null,
        transactionPrincipalId: topic?.transactionalPrincipalId || null,
        // isProducerRequired: topic?.isWriteAccess === 'Yes',
        // isConsumerRequired: topic?.isReadAccess === 'Yes',
    }));
};
export const getKafkaValidPayload = (formData, order) => {
    const userClaims = getLoggedUserClaims();

    return {
        project: order?.appShortName || null, //     ask drona
        environment: order?.environment,
        ldapGroup: formData?.ldap,
        program: formData?.program === 'Not Applicable' ? 'Global Shared' : formData?.program,
        sector: order?.sector || null,
        catalogType: 'Kafka',
        budgetCode: order?.budgetCode || null,
        applicationService: order?.appProfile,
        division: order?.division || null,
        applicationRegion: order?.region || null,
        catalogServiceCode: RI003, //   order?.catalogCode
        businessUnit: formData?.businessUnit,
        appOwner: order?.appOwnerDetails?.gpid,
        createdBy: userClaims?.accessToken?.claims?.gpid,
        topics: getKafkaTopics(formData?.kafkatopics),
    };
};
