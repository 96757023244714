import { titleCase } from 'utils';
import { ROLE_TO_SHOW, SIGNATURE_ROLE_TO_SHOW, SAMLRequestType, OIDCRequestType, MACHINERequestType } from './constant';

export const getOrderPayload = ({ order }) => {
    let applicationTypeValue = '';
    if (order?.requestType === SAMLRequestType) {
        applicationTypeValue = 'Web application';
    } else if (order?.requestType === OIDCRequestType) {
        applicationTypeValue = order?.appType;
    }

    let businessJustificationValue = '';
    if (
        order?.requestType === SAMLRequestType &&
        SIGNATURE_ROLE_TO_SHOW?.includes(order?.dataClassification?.toLowerCase()) &&
        order?.isCertificateAvailable === 'yes'
    ) {
        businessJustificationValue = { certificate: order?.serviceProviderSigningCert };
    } else if (order?.requestType === OIDCRequestType) {
        businessJustificationValue = { businessJustification: order?.businessJustification };
    }

    return {
        requestedType: order?.requestType,
        businessApplication: order?.businessApplication,
        applicationCI: order?.applicationCI,
        sector: order?.sector,
        environment: order?.environment === 'Pre-Production' ? 'Pre-Prod' : order?.environment,
        ...(order?.requestType !== MACHINERequestType && { applicationType: applicationTypeValue, dataClasification: order?.dataClassification }),
        ...(order?.authorizationRolesRequired === 'yes' &&
            ROLE_TO_SHOW?.includes(order?.dataClassification?.toLowerCase()) && { roles: order?.roles?.map((app) => app?.role) }),
        applicationName: order?.applicationName?.trim(),
        ...(order?.requestType !== MACHINERequestType && { applicationURL: order?.applicationUrl, applicationShortName: order?.appShortName }),
        applicationOwner: order?.appOwnerDetails?.gpid,
        authorizationRolesRequired: titleCase(order?.authorizationRolesRequired || 'no'),
        ...(order?.requestType === SAMLRequestType && {
            recipientURL: order?.recipientUrl,
            destinationURL: order?.destinationUrl,
            entityId: order?.audienceURI,
            nameIdFormat: order?.nameIdFormat,
        }),
        ...(order?.requestType === OIDCRequestType && {
            logoutURL: order?.logoutUrl,
        }),
        ...(order?.requestType !== MACHINERequestType && { isCertificateAvailable: titleCase(order?.isCertificateAvailable || 'no') }),
        ...businessJustificationValue,
        ...(order?.requestType === OIDCRequestType &&
            order?.dataClassification?.toLowerCase() === 'internal' && {
                accessRestrictions: order?.accessRestrictions,
            }),
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;

    return {
        // catalogCode: catalog?.catalogServiceCode,
        requestType: catalogConfig?.requestedType,
        businessApplication: catalogConfig?.businessApplication,
        applicationCI: catalogConfig?.applicationCI,
        businessApplicationDetails: {
            label: catalogConfig?.businessApplication,
            value: catalogConfig?.businessApplication,
        },
        sector: catalogConfig?.sector,
        environment: catalogConfig?.environment === 'Pre-Prod' ? 'Pre-Production' : catalogConfig?.environment,
        ...(catalogConfig?.requestedType !== MACHINERequestType && {
            appType: catalogConfig?.applicationType,
            dataClassification: catalogConfig?.dataClasification,
        }),
        ...(catalogConfig?.requestedType === SAMLRequestType && { nameIdFormat: catalogConfig?.nameIdFormat }),
        applicationName: catalogConfig?.applicationName,
        ...(catalogConfig?.requestedType !== MACHINERequestType && {
            applicationUrl: catalogConfig?.applicationURL,
            appShortName: catalogConfig?.applicationShortName,
        }),
        appOwner: null,
        appOwnerDetails: { name: data?.manager?.name, gpid: data?.manager?.id },
        authorizationRolesRequired: catalogConfig?.authorizationRolesRequired?.toLowerCase(),
        isCertificateAvailable: catalogConfig?.isCertificateAvailable?.toLowerCase(),
        ...(catalogConfig?.roles?.length && {
            roles: catalogConfig?.roles?.map((role) => {
                return { role };
            }),
        }),
        ...(catalogConfig?.requestedType === SAMLRequestType && catalogConfig?.certificate && { serviceProviderSigningCert: catalogConfig?.certificate }),
        ...(catalogConfig?.requestedType === SAMLRequestType &&
            catalogConfig?.businessJustification && { businessJustification: catalogConfig?.businessJustification }),
        ...(catalogConfig?.requestedType === SAMLRequestType && {
            recipientUrl: catalogConfig?.recipientURL,
            destinationUrl: catalogConfig?.destinationURL,
            audienceURI: catalogConfig?.entityId,
        }),
        ...(catalogConfig?.requestedType === OIDCRequestType && {
            logoutUrl: catalogConfig?.logoutURL,
        }),
        ...(catalogConfig?.requestedType === OIDCRequestType &&
            catalogConfig?.dataClasification?.toLowerCase() === 'internal' && { accessRestrictions: catalogConfig?.accessRestrictions }),
        isTouched: false,
    };
};

export const getSsoResubmitRequestPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    let businessJustificationValue = '';
    if (
        order?.requestType === SAMLRequestType &&
        SIGNATURE_ROLE_TO_SHOW?.includes(catalog?.dataClassification?.toLowerCase()) &&
        order?.isCertificateAvailable === 'yes'
    ) {
        businessJustificationValue = { certificate: catalog?.serviceProviderSigningCert };
    } else if (order?.requestType === OIDCRequestType) {
        businessJustificationValue = { businessJustification: catalog?.businessJustification };
    }

    return {
        requestedType: catalog?.requestedType,
        businessApplication: order?.businessApplication,
        applicationCI: order?.applicationCI,
        sector: catalog?.sector,
        environment: catalog?.environment === 'Pre-Production' ? 'Pre-Prod' : catalog?.environment,
        ...(catalog?.requestedType !== MACHINERequestType && { applicationType: catalog?.applicationType, dataClasification: catalog?.dataClasification }),
        ...(catalog?.roles?.length && {
            roles: catalog?.roles,
        }),
        applicationName: catalog?.applicationName?.trim(),
        ...(catalog?.requestedType !== MACHINERequestType && { applicationURL: catalog?.applicationURL, applicationShortName: catalog?.applicationShortName }),
        applicationOwner: catalog?.applicationOwner,
        authorizationRolesRequired: titleCase(catalog?.authorizationRolesRequired || 'no'),
        isCertificateAvailable: titleCase(catalog?.isCertificateAvailable || 'no'),
        ...(catalog?.requestedType === SAMLRequestType && {
            recipientURL: catalog?.recipientURL,
            destinationURL: catalog?.destinationURL,
            entityId: catalog?.entityId,
            nameIdFormat: catalog?.nameIdFormat,
        }),
        ...(catalog?.requestedType === OIDCRequestType && {
            logoutURL: catalog?.logoutURL,
        }),
        ...(catalog?.requestedType === SAMLRequestType &&
            catalog?.certificate &&
            catalog?.entityId &&
            catalog?.isCertificateAvailable === 'yes' && {
                certificate: catalog?.certificate,
            }),
        ...businessJustificationValue,
        ...(catalog?.requestedType === OIDCRequestType &&
            catalog?.dataClasification?.toLowerCase() === 'internal' && {
                accessRestrictions: catalog?.accessRestrictions,
            }),
    };
};

export const resetOrder = {
    catalogCode: null,
    applicationCI: null,
    sector: null,
    environment: null,
    envShortName: null,
    businessApplication: null,
    businessApplicationDetails: null,
    appType: null,
    dataClassification: null,
    isCertificateAvailable: 'no',
    applicationName: null,
    applicationUrl: null,
    logoutUrl: null,
    appShortName: null,
    appOwner: null,
    appOwnerDetails: null,
    requestType: null,
    destinationUrl: null,
    recipientUrl: null,
    audienceURI: null,
    serviceProviderSigningCert: null,
    businessJustification: null,
    isTouched: false,
    nameIdFormat: null,
    accessRestrictions: [],
};

const commonRegex = /^(http|https):\/\/(?!localhost)([a-zA-Z0-9.-]+)(:\d+)?(\/[a-zA-Z0-9/.-]+)?\/?$|^urn:[a-zA-Z0-9.-]+$/;
const productionRegex = /^(http|https):\/\/(?!localhost)([a-zA-Z0-9.-]+)(:\d+)?(\/[a-zA-Z0-9/.-]+)?\/?$|^urn:[a-zA-Z0-9.-]+$/;
const applicationUrlRegex = /^(http|https):\/\/([a-zA-Z0-9.-]+)(:\d+)?(\/[a-zA-Z0-9/.-]+)?$|^urn:[a-zA-Z0-9.-]+$/;

export const urlConfig = {
    common: {
        regex: commonRegex,
        message: `URL is Invalid.`,
    },
    production: {
        regex: productionRegex,
        message: `URL is Invalid.`,
    },
    applicationURL: {
        regex: applicationUrlRegex,
        message: `URL is Invalid.`,
    },
};

export const validateLoginLogoutURL = (url, environment, requestType) => {
    let config;

    if (requestType === OIDCRequestType) {
        config = urlConfig?.common;
    } else if (requestType === SAMLRequestType && environment?.toLowerCase() === 'production') {
        config = urlConfig?.production;
    } else {
        config = urlConfig?.applicationURL;
    }

    if (!config) {
        throw new Error(`Invalid environment: ${environment}`);
    }

    const isValid = url !== null && url !== '' ? config.regex.test(url) : true;

    return {
        isValid,
        message: isValid ? 'Valid URL' : config.message,
    };
};
