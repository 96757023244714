import React from 'react';
import { Stack, FormControl, Box, FormControlLabel } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import SsoOktaStyles, { CustomRadioGroup, CustomRadio } from 'pages/sso-okta/style';
// import { ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';

const RequestType = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const setRequestType = (requestType) => {
        setOrder({
            ...order,
            requestType,
            applicationCI: null,
            applicationCIDetails: null,
            applicationOwner: null,
            applicationOwnerDetails: null,
            applicationName: null,
            businessApplicationDetails: null,
            businessApplication: null,
            applicationUrl: null,
            environment: null,
            sector: null,
            organization: null,
            azureRepo: null,
            member: null,
            memberDetails: null,
            roles: [{ role: '' }],
            authorizationRolesRequired: 'no',
            recipientUrl: null,
            destinationUrl: null,
            appShortName: null,
            dataClassification: null,
            appOwner: null,
            appOwnerDetails: null,
            appType: null,
            audienceURI: null,
            logoutUrl: null,
            serviceProviderSigningCert: null,
            isTouched: false,
        });
    };
    const handleOnSelectChange = (selected) => {
        setRequestType(selected);
    };
    return (
        <>
            <Stack direction="row" spacing={1} sx={SsoOktaStyles.stack}>
                <FormControl sx={SsoOktaStyles?.form?.radioLabel}>
                    <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} data-testid="authRadioGrp">
                        Select type of single sign-on you would like to request
                    </Box>
                    <CustomRadioGroup
                        aria-label="AuthorizationRolesRadio"
                        name="authorizationRolesRequiredBtnGroup"
                        row
                        value={order?.requestType || null}
                        onChange={(event, value) => handleOnSelectChange(value)}
                    >
                        <FormControlLabel value="OIDC - OpenID Connect" control={<CustomRadio />} label="OIDC - OpenID Connect" />
                        <FormControlLabel
                            value="OIDC OAUTH2.0 - Machine2Machine(API Service)"
                            sx={{
                                marginLeft: '60px',
                            }}
                            control={<CustomRadio />}
                            label="OIDC - Machine2Machine(API Service)"
                        />
                        <FormControlLabel
                            value="SAML 2.0"
                            sx={{
                                marginLeft: '60px',
                            }}
                            control={<CustomRadio />}
                            label="SAML 2.0"
                        />
                    </CustomRadioGroup>
                </FormControl>
            </Stack>
        </>
    );
};

export default RequestType;
