import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { RIStyles } from 'pages/reference-implementation/Style';
import Select from 'pages/reference-implementation/components/select';
import Btn from 'components/button';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';

const WebApp = (props) => {
    const { catalogCode } = useParams();
    const { resource, handleDrawerAction } = props;
    const [formData, setFormData] = useState({
        appServiceType: resource?.configureData?.appServiceType || '',
        osType: resource?.configureData?.osType || '',
        appStack: resource?.configureData?.appStack || '',
    });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [appServiceTypes, setAppServiceTypes] = useState([]);
    const [osTypes, setOsTypes] = useState([]);
    const [appStackOptions, setAppStackOptions] = useState([]);
    const riEndpoints = getEndpointForCatalogCode(catalogCode);
    const typesGetApi = useMutation(() => axios.get(`${riEndpoints.Jwt.webAppServiceDetails}`));

    useEffect(() => {
        typesGetApi.mutate();
    }, []);

    useEffect(() => {
        if (typesGetApi.isSuccess) {
            const typesData = typesGetApi.data.data;
            const appServiceTypeData = typesData.map((item) => ({ label: item.appServiceType, value: item.appServiceType }));
            setAppServiceTypes(appServiceTypeData);

            const selectedServiceType = typesData.find((item) => item.appServiceType === formData.appServiceType);
            if (selectedServiceType) {
                const osTypesData = selectedServiceType.osTypes.map((osType) => ({ label: osType.osType, value: osType.osType }));
                setOsTypes(osTypesData);

                const selectedOsType = selectedServiceType.osTypes.find((osType) => osType.osType === formData.osType);
                if (selectedOsType) {
                    setAppStackOptions(selectedOsType.applicationStacks.map((stack) => ({ label: stack, value: stack })));
                }
            }
        }
    }, [typesGetApi.isSuccess, formData.appServiceType, formData.osType]);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.appServiceType) {
            newErrors.appServiceType = { type: 'required', message: 'Api Service Type is required' };
        }
        if (!formData.osType) {
            newErrors.osType = { type: 'required', message: 'OS Type is required' };
        }
        if (!formData.appStack) {
            newErrors.appStack = { type: 'required', message: 'Application Stack is required' };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        let updatedFormData = { ...formData, [key]: value };

        if (key === 'appServiceType') {
            updatedFormData = {
                ...updatedFormData,
                osType: '',
                appStack: '',
            };
        } else if (key === 'osType') {
            updatedFormData = {
                ...updatedFormData,
                appStack: '',
            };
        }

        setFormData(updatedFormData);
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        setTouched({
            osType: true,
            appServiceType: true,
            appStack: true,
        });
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    const appServicevalue = React.useMemo(
        () => appServiceTypes.find((item) => item?.value === formData?.appServiceType) ?? null,
        [formData?.appServiceType, appServiceTypes]
    );
    const osTypeValue = React.useMemo(() => osTypes.find((item) => item?.value === formData?.osType) ?? null, [formData?.osType, osTypes]);
    const appStackValue = React.useMemo(
        () => appStackOptions.find((item) => item?.value === formData?.appStack) ?? null,
        [formData?.appStack, appStackOptions]
    );

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            key="app-service-type"
                            label="App Service Type *"
                            placeholder="Select App Service Type"
                            options={appServiceTypes}
                            handleOnSelect={(selectedOption) => handleOnSelectChange('appServiceType', selectedOption?.value)}
                            value={appServicevalue}
                            isRequired={shouldShowError('appServiceType')}
                        />
                    </Grid>
                    {formData?.appServiceType && (
                        <Grid item xs={12}>
                            <Select
                                key="os-type"
                                label="OS Type *"
                                placeholder="Select OS Type"
                                options={osTypes}
                                handleOnSelect={(selectedOption) => handleOnSelectChange('osType', selectedOption?.value)}
                                value={osTypeValue}
                                isRequired={shouldShowError('osType')}
                            />
                        </Grid>
                    )}
                    {formData?.osType && (
                        <Grid item xs={12}>
                            <Select
                                key="app-stack"
                                label="Application Stack *"
                                placeholder="Select Application Stack"
                                options={appStackOptions}
                                handleOnSelect={(selectedOption) => handleOnSelectChange('appStack', selectedOption?.value)}
                                value={appStackValue}
                                isRequired={shouldShowError('appStack')}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

WebApp.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func,
};

WebApp.defaultProps = {
    resource: null,
    handleDrawerAction: PropTypes.func,
};

export default WebApp;
