export const ROLE_TO_SHOW = ['confidential', 'restricted'];
export const SIGNATURE_ROLE_TO_SHOW = ['confidential'];
export const RESTRICTED_ROLE_TO_SHOW = ['restricted'];
export const EXCLUDE_ROLE_TO_SHOW = ['restricted', 'internal', 'confidential'];

export const SAMLRequestType = 'SAML 2.0';
export const OIDCRequestType = 'OIDC - OpenID Connect';
export const MACHINERequestType = 'OIDC OAUTH2.0 - Machine2Machine(API Service)';

export const urlRegex = /^(http|https):\/\/[^ "]+\.[^ "]{2,}$/;
export const HARD_CODED_GPIDS = [
    {
        gpid: '71001190',
        name: 'Shilpa Pulimi',
    },
    {
        gpid: '80970694',
        name: 'Venu Mandava',
    },
    {
        gpid: '80231631',
        name: 'Sreedhar Reddy Siriganna Gari',
    },
];

export default {};
