/* eslint-disable import/no-named-default */
import { default as ApplicationService } from './ApplicationService';
import { default as ApplicationOwner } from './ApplicationOwner';
import { default as CertificateOwnerOne } from './CertificateOwnerOne';
import { default as CertificateOwnerTwo } from './CertificateOwnerTwo';
import { default as Sector } from './Sector';
import { default as Division } from './Division';
import { default as Environment } from './Environment';
import { default as ApplicationRegion } from './ApplicationRegion';
import { default as Department } from './Department';
import { default as Program } from './Program';
import { default as AksCluster } from './AksCluster';
import { default as AksNamespace } from './AksNamespace';
import { default as CostCenter } from './CostCenter';
import { default as VirtualServiceType } from './project/VirtualServiceType';
import { default as ServiceName } from './project/ServiceName';
import { default as VirtualServiceName } from './project/VirtualServiceName';
import { default as ServicePort } from './project/ServicePort';
import { default as ContextPath } from './project/ContextPath';
import { default as CorsPolicy } from './project/CorsPolicy';
import { default as AllowedMethods } from './project/AllowedMethods';
import { default as AllowedHeaders } from './project/AllowedHeaders';
import { default as AllowedOrigins } from './project/AllowedOrigins';
import { default as ExposeHeaders } from './project/ExposeHeaders';
import { default as MultiContextPath } from './project/multiSelections/MultiContextPath';
import { default as MultiServiceNameContextPath } from './project/multiSelections/MultiServiceNameContextPath';
import { default as MultiHostName } from './project/MultiHostName';
import { default as CustomDomainSelected } from './project/customDomain/CustomDomainSelected';
import { default as CustomDomainName } from './project/customDomain/CustomDomainName';
import { default as Region } from './Region';

const Form = {
    ApplicationService,
    ApplicationOwner,
    CertificateOwnerOne,
    CertificateOwnerTwo,
    Sector,
    Division,
    Environment,
    ApplicationRegion,
    Department,
    Program,
    AksCluster,
    AksNamespace,
    CostCenter,
    VirtualServiceType,
    ServiceName,
    VirtualServiceName,
    ServicePort,
    ContextPath,
    CorsPolicy,
    AllowedMethods,
    AllowedHeaders,
    AllowedOrigins,
    ExposeHeaders,
    MultiContextPath,
    MultiServiceNameContextPath,
    MultiHostName,
    CustomDomainSelected,
    CustomDomainName,
    Region,
};

export default Form;
