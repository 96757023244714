import {
    RICatalogCode003,
    RICatalogCode003O,
    RICatalogCode003A,
    RICatalogCode004,
    RICatalogCode004A,
    RICatalogCode005,
} from 'pages/reference-implementation/utils/RICatalogCode';
import { selectedEnv } from './constant';

export const ShowAppUrlBasedOnCatalogCode = [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005];
export const checkIsApplicable = (options) => !(options?.length === 1 && options?.[0]?.value?.toLowerCase() === 'not applicable');
export const isShowAppRegionField = ({ order }) => {
    const sector = order?.sector?.toLowerCase();
    // if (order?.sector && ['amesa']?.includes(sector) && order?.catalogCode === RICatalogCode005) {
    //     return true;
    // }
    if (
        order?.sector &&
        ['cgf', 'amesa', 'snt dps']?.includes(sector) &&
        [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode004, RICatalogCode004A, RICatalogCode005]?.includes(order?.catalogCode)
    ) {
        return true;
    }
    return false;
};
export const isAksnsConfigured = ({ order }) => {
    let configured = false;
    const aksnsOrderComponent = {
        ...order?.resourceMgmt?.[selectedEnv]?.components?.filter(
            (component) => component?.componentId === 'RI003-AKSNS' && component?.groupType === 'mandatoryComponents'
        )?.[0],
    };

    if (aksnsOrderComponent?.configureData && Object?.keys(aksnsOrderComponent?.configureData)?.length > 0) {
        configured = true;
    }
    return configured;
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    catalogCode: null,
    projectId: null,
    projectDetails: null,
    businessUnit: null,
    sector: null,
    program: null,
    appUrl: null,
    appContextPath: null,
    division: null,
    budgetCode: null,
    budgetCodeDetails: null,
    billingInfo: null,
    environment: null,
    appProfile: null,
    appProfileDetails: null,
    dataClassification: null,
    environmentDetails: null,
    businessAppService: 'No',
    businessApp: null,
    appService: null,
    appOwner: null,
    appOwnerDetails: null,
    certificateOwnerOne: null,
    certificateOwnerOneDetails: null,
    certificateOwnerTwo: null,
    certificateOwnerTwoDetails: null,
    applicationName: null,
    appShortName: null,
    businessContact: null,
    businessContactDetails: null,
    privilegedADGroup: null,
    nonPrivilegedADGroup: null,
    nonPrivilegedADGroupList: [{ readerValue: '' }],
    privilegedADGroupList: [{ contributorValue: '' }],
    privilegedADGroupOwner: null,
    privilegedADGroupOwnerDetails: null,
    nonPrivilegedADGroupOwner: null,
    nonPrivilegedADGroupOwnerDetails: null,
    appSupportMailId: null,
    isTouched: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};

export function transformModifiedComponent(components, componentGroups) {
    const modifiedComponents = componentGroups.flatMap((group) => {
        return group.components.map((component) => {
            const getComponentById = components.find((apiComponent) => apiComponent['component-id'] === component.componentId);
            const configureData =
                getComponentById && Object.keys(getComponentById).length > 1
                    ? Object.fromEntries(Object.entries(getComponentById).filter(([key]) => key !== 'component-id'))
                    : null;
            return configureData ? { ...component, configureData } : { ...component };
        });
    });
    return modifiedComponents;
}

const getSelectedComponentsForEdit = (data, componentGroups, orderComponents) => {
    const componentMap = new Map();
    // Create a map of orderComponents for quick lookup
    orderComponents.forEach((orderComponent) => {
        const componentId = orderComponent['component-id'];
        componentMap.set(componentId, { ...orderComponent });
    });

    const selectedComponents = [];
    // Iterate through each group in componentGroups
    componentGroups.forEach((group) => {
        group.components.forEach((component) => {
            const { componentId } = component;
            const orderComponent = componentMap.get(componentId);
            if (orderComponent) {
                // Initialize the component object to be added to the local state
                const localComponent = { ...component };
                // Assign configureData based on configurationComponentCode
                if (localComponent.configurable && orderComponent) {
                    switch (localComponent.configurationComponentCode) {
                        case 'AZSERVICEBUS':
                            localComponent.configureData = {
                                serviceBusCapacity: orderComponent.size,
                                partitionCount: orderComponent['partition-count'],
                            };
                            break;
                        case 'AZCOSMOSMONGO':
                        case 'AZCOSMOSNOSQL':
                            localComponent.configureData = {
                                isGeoReplication: orderComponent['geo-replication'],
                            };
                            break;
                        case 'AZSQLPAAS':
                            localComponent.configureData = {
                                adSqlAccount: orderComponent['sql-account-name'],
                                IsADSQLAccount: orderComponent['sql-account-name'] ? 'Yes' : 'No',
                                ...(orderComponent['sql-account-name'] === null && {
                                    sqlGrpOwner: {
                                        groupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                                        groupOwnerDetails: {
                                            label: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                                            gpid: data?.activeDirectoryGroupOwner?.id,
                                            name: data?.activeDirectoryGroupOwner?.name,
                                            email: data?.activeDirectoryGroupOwner?.emailId,
                                        },
                                    },
                                }),
                                ...(orderComponent['sql-account-name'] !== null && {
                                    sqlGrpOwner: null,
                                }),
                            };
                            break;
                        case 'WEBAPP':
                            localComponent.configureData = {
                                appServiceType: orderComponent['app-service-type'],
                                osType: orderComponent['os-type'],
                                appStack: orderComponent['app-stack'],
                            };
                            break;
                        case 'CONTAINERAPP':
                            localComponent.configureData = {
                                containerType: orderComponent['container-type'],
                                appServiceType: orderComponent['app-service-type'],
                                osType: orderComponent['os-type'],
                                appStack: orderComponent['app-stack'],
                            };
                            break;
                        default:
                            break;
                    }
                }

                // Add the configured component to the selectedComponents array
                selectedComponents.push(localComponent);
            }
        });
    });

    return selectedComponents;
};
export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null, componentGroups) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const envConfiguration = catalogConfig?.environmentConfigurations?.[0];
    const privilegedADGroup = envConfiguration?.contributorAdGroups?.length ? 'Yes' : 'No';
    const nonPrivilegedADGroup = envConfiguration?.readerAdGroups?.length ? 'Yes' : 'No';
    const resourceMgmtComponents = { components: getSelectedComponentsForEdit(data, componentGroups, envConfiguration?.components) };
    return {
        catalogCode: catalog?.catalogServiceCode,
        appProfile: catalogConfig?.applicationService,
        sector: catalogConfig?.sector,
        appUrl: envConfiguration?.applicationUrl,
        appContextPath: catalogConfig?.applicationContextPath,
        ...(catalogConfig?.sector?.toLowerCase() === 'cgf' && { division: catalogConfig?.division }),
        ...(['cgf', 'amesa', 'snt dps']?.includes(catalogConfig?.sector?.toLowerCase()) && { region: catalogConfig?.applicationRegion }),
        budgetCode: catalogConfig?.costCenter,
        environment: envConfiguration?.environmentDisplayName,
        dataClassification: catalogConfig?.dataClassification,
        appService: !catalogConfig?.applicationService
            ? `${catalogConfig?.applicationName} ${['production']?.includes(catalogConfig?.environment?.toLowerCase()) ? 'PROD' : 'NON-PROD'}`
            : catalogConfig?.applicationService,
        applicationName: catalogConfig?.applicationName,
        appShortName: catalogConfig?.applicationShortName,
        // appOwner: data?.manager?.id,
        appOwner: null,
        appOwnerDetails: { name: data?.manager?.name, gpid: data?.manager?.id },
        businessContact: null,
        businessContactDetails: { gpid: catalogConfig?.businessOwnerId },
        privilegedADGroup,
        nonPrivilegedADGroup,
        ...(privilegedADGroup === 'Yes' && {
            privilegedADGroupList: envConfiguration?.contributorAdGroups.map((app) => ({
                contributorValue: app,
                contributorInputValue: app,
            })),
        }),
        ...(nonPrivilegedADGroup === 'Yes' && {
            nonPrivilegedADGroupList: envConfiguration?.readerAdGroups.map((app) => ({
                readerValue: app,
                readerInputValue: app,
            })),
        }),
        ...(!['production']?.includes(envConfiguration?.environmentDisplayName?.toLowerCase()) &&
            nonPrivilegedADGroup === 'No' && {
                nonPrivilegedADGroupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                nonPrivilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        ...(!['production']?.includes(envConfiguration?.environmentDisplayName?.toLowerCase()) &&
            privilegedADGroup === 'No' && {
                privilegedADGroupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        ...(['production']?.includes(envConfiguration?.environmentDisplayName?.toLowerCase()) &&
            privilegedADGroup === 'No' && {
                privilegedADGroupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        createdBy: data?.created?.by,
        appSupportMailId: catalogConfig?.appSupportMailId,
        certificateOwnerOneDetails: { gpid: catalogConfig?.certificateOwnerOne, name: catalogConfig?.certificateOwnerOneName },
        certificateOwnerTwoDetails: { gpid: catalogConfig?.certificateOwnerTwo, name: catalogConfig?.certificateOwnerTwoName },
        resourceMgmt: {
            [selectedEnv]: resourceMgmtComponents,
        },
        isTouched: false,
    };
};

export const getComponentsFromSchema = ({ componentGroups = [], exceptPrompt = [], particularPrompt = [] }) => {
    // If particularPrompt is specified, filter groups by their groupType
    if (particularPrompt.length > 0) {
        const filteredGroups = componentGroups?.filter((group) => particularPrompt.includes(group.groupType));
        return filteredGroups?.map((group) => ({
            ...group,
            components: group.components.filter((component) => particularPrompt.includes(component.componentId) || particularPrompt.includes(group.groupType)),
        }));
    }

    // Filter groups to exclude specified groupTypes
    const filteredGroups = componentGroups?.filter((group) => !exceptPrompt.includes(group.groupType));

    // Return the filtered groups with their components
    return filteredGroups?.map((group) => ({
        ...group,
        components: group.components.filter((component) => !exceptPrompt.includes(component.componentId)),
    }));
};

export const generateConfigPayload = (components) => {
    return components.map((component) => {
        const result = {
            'component-id': component?.componentId,
        };

        if (component?.configurable && component?.configureData) {
            const { configurationComponentCode, configureData } = component;
            switch (configurationComponentCode) {
                case 'AZSERVICEBUS':
                    result.size = configureData?.serviceBusCapacity;
                    break;
                case 'AZCOSMOSMONGO':
                case 'AZCOSMOSNOSQL':
                    result['geo-replication'] = configureData?.isGeoReplication === 'Yes';
                    break;
                case 'AZSQLPAAS':
                    result['sql-account-name'] = configureData?.adSqlAccount || null;
                    break;
                case 'WEBAPP':
                case 'CONTAINERAPP':
                    if (configureData?.containerType) {
                        result['container-type'] = configureData?.containerType;
                    }
                    if (configureData?.appServiceType) {
                        result['app-service-type'] = configureData?.appServiceType;
                    }
                    if (configureData?.osType) {
                        result['os-type'] = configureData?.osType;
                    }
                    if (configureData?.appStack) {
                        result['app-stack'] = configureData?.appStack;
                    }
                    break;
                default:
                    return null;
            }
        }

        return result;
    });
};
const getUniqueComponentIdsFromFlatArray = (components = []) => {
    return Array.from(new Set(components?.map((component) => component?.componentId)));
};

const getUniqueComponentIdsFromGroups = (componentGroups) => {
    const allComponentIds = componentGroups?.flatMap((group) => group?.components.map((component) => component.componentId));
    return Array.from(new Set(allComponentIds));
};

// Comparison function
export const compareComponentIdsLength = (components, componentGroups) => {
    const uniqueIdsFromFlatArray = getUniqueComponentIdsFromFlatArray(components);
    const uniqueIdsFromGroups = getUniqueComponentIdsFromGroups(componentGroups);

    const lengthFromFlatArray = uniqueIdsFromFlatArray.length;
    const lengthFromGroups = uniqueIdsFromGroups.length;

    return {
        lengthFromFlatArray,
        lengthFromGroups,
        areEqual: lengthFromFlatArray === lengthFromGroups,
    };
};
