import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { filterMenuItemsByRole, getUserScopes, LoggedUser } from 'utils';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import DropDownMenu from 'components/dropdown-menu';
import { useHeaderStyle as style } from 'assets/style';
import { cartCount } from 'pages/cart/store';
import { Badge, AppBar, Toolbar, Avatar, Box, Button, Grid, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { menuItems as MainNavigation } from 'utils/menuItems';
import CascadingHoverMenus from 'components/cascading-hover-menus';

const AppHeader = ({ menus }) => {
    const history = useHistory();
    const location = useLocation();
    const [cmpCartCount, setCartCount] = useRecoilState(cartCount);
    const cartCountApi = useMutation(() => axios.get(endPoints.cart.getCartCount));
    const persona = MainNavigation.find((menu) => menu.id === 'mypersona' && menu);
    const [activemenuItem, setActiveMenuItem] = React.useState(localStorage.getItem('activeMenu'));

    const accessScopes = getUserScopes();
    const getActiveItem = (path) => {
        if (path?.includes('/reference-implementation-services') || path?.includes('/reference-implementation-provisioning/')) {
            localStorage.setItem('activeMenu', 'Solutions');
            setActiveMenuItem('Solutions');
        } else if (
            path === '/services' ||
            path?.includes('provisioning') ||
            path?.includes('azure-key-vault') ||
            path?.includes('azure-load-balancer') ||
            path?.includes('app-microservices-stack') ||
            path?.includes('azure-application-gateway-url-onboarding') ||
            path?.includes('azure-internal-application-gateway-url-onboarding') ||
            path?.includes('azure-external-public-load-balancer-url-onboarding')
        ) {
            localStorage.setItem('activeMenu', 'Services');
            setActiveMenuItem('Services');
        } else if (path === '/orders') {
            localStorage.setItem('activeMenu', 'Orders');
            setActiveMenuItem('Orders');
        } else if (path === '/approvals') {
            localStorage.setItem('activeMenu', 'Approvals');
            setActiveMenuItem('Approvals');
        } else if (path === '/cart') {
            localStorage.setItem('activeMenu', 'cart');
            setActiveMenuItem('cart');
        } else if (
            path?.includes('/dashboard') ||
            path?.includes('/sku') ||
            path?.includes('/app-profile') ||
            path?.includes('/app-details') ||
            path?.includes('/landing-zones') ||
            path?.includes('/nsg-common-rule') ||
            path?.includes('/day2-operation') ||
            path?.includes('/sso-okta') ||
            path?.includes('/access-management-onboard-developers') ||
            path.includes('/mdt-onborading') ||
            path.includes('/security-tool') ||
            path.includes('/access-management-myidm') ||
            path.includes('/service-account-ci') ||
            path.includes('/project-onboarding') ||
            path.includes('/service-account-creation')
        ) {
            localStorage.setItem('activeMenu', 'More');
            setActiveMenuItem('More');
        } else {
            localStorage.setItem('activeMenu', '');
            setActiveMenuItem('');
        }
    };
    const initials = (str) =>
        str
            .match(/\b(\w)/g)
            .join('')
            .substring(0, 2)
            .toUpperCase();

    React.useEffect(() => {
        getActiveItem(location?.pathname);
    }, [location?.pathname]);
    React.useEffect(() => {
        cartCountApi.mutate();
    }, []);

    React.useEffect(() => {
        if (cartCountApi?.isSuccess) {
            setCartCount(cartCountApi?.data?.data?.itemCount || 0);
        }
    }, [cartCountApi?.isSuccess]);
    const handleMenuItemChange = (val) => {
        localStorage.setItem('activeMenu', val);
        setActiveMenuItem(val);
    };
    return (
        <>
            <AppBar data-testid="header" position="static" sx={style.header} color="inherit">
                <Toolbar variant="dense">
                    <Grid container alignItems="center">
                        <Grid item>
                            <Box sx={style.title}>
                                <Button component={NavLink} to="/" disableRipple disableFocusRipple>
                                    <img src="/static/images/logo.png" alt="" width={90} />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item sm sx={style.menuWrapper}>
                            <CascadingHoverMenus activeMenuItem={activemenuItem} menuItems={filterMenuItemsByRole(menus, accessScopes)} />
                        </Grid>
                        <Grid item sx={style.menuWrapper}>
                            <Box
                                data-testid="cart-icon"
                                onClick={() => {
                                    history.push('/cart');
                                    setActiveMenuItem('');
                                    handleMenuItemChange('cart');
                                }}
                                sx={{ ...style.cart.container, borderBottom: `${activemenuItem === 'cart' && '4px solid #64C6EA'}` }}
                                name="cart"
                            >
                                <Badge badgeContent={cmpCartCount}>
                                    <FaShoppingCart style={style.cart.icon} />
                                </Badge>
                            </Box>
                            <DropDownMenu
                                alignment="right"
                                name={persona.name}
                                subMenu={persona?.children}
                                handleChange={handleMenuItemChange}
                                activemenuItem={activemenuItem}
                            >
                                <ListItem sx={{ borderBottom: `${activemenuItem === persona.name && '4px solid #64C6EA'}` }}>
                                    <ListItemAvatar sx={style.avatarSize}>
                                        <Avatar sx={style.small} type="button">
                                            {initials(LoggedUser().FullName())}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primaryTypographyProps={style.avatarTxt} primary={LoggedUser().FullName()} />
                                </ListItem>
                            </DropDownMenu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};

AppHeader.propTypes = {
    menus: PropTypes.any,
};
AppHeader.defaultProps = {
    menus: [],
};

export default React.memo(AppHeader);
