import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isAppUrlValid } from 'pages/reference-implementation/provisioning/RI002O/store';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { environmentConfig, validateAppURL } from 'pages/reference-implementation/provisioning/RI002O/utils/validation';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const AppUrl = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setAppUrlVaild] = useRecoilState(isAppUrlValid);
    const [helperText, setHelperText] = React.useState(false);
    const [helperMsg, setHelperMsg] = React.useState('');

    const setAppUrl = (appUrl = null) => {
        setOrder({
            ...order,
            appUrl,
        });
    };

    const getErrorMessage = (environment) => {
        const config = environmentConfig[environment?.toLowerCase()];
        return config?.message?.replace('Invalid. Must', 'must') || 'Please choose the environment before filling custom domain URL';
    };

    React.useEffect(() => {
        const { isValid, message } = validateAppURL(order?.appUrl, order?.environment);
        if (order?.appUrl && order?.appUrl !== '' && !isValid) {
            setHelperText(true);
            setAppUrlVaild(false);
            setHelperMsg(message);
        } else if (order?.appUrl === null && !isValid) {
            setAppUrlVaild(false);
        } else if (order?.appUrl === '') {
            setAppUrlVaild(false);
            setHelperMsg('');
            setHelperText(false);
        } else if (order?.appUrl && isValid) {
            setHelperText(false);
            setAppUrlVaild(true);
            setHelperMsg('');
        }
    }, [order?.appUrl, order?.environment]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.appUrl) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.appUrl && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appUrl, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="app-url-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Custom Domain URL" isRequired />}
                error={helperText}
                helperText={helperText && <span>{helperMsg}</span>}
                value={order?.appUrl || ''}
                onChange={(e) => setAppUrl(e.target.value)}
                className={validateInput()}
            />
            <InputInfo title={getErrorMessage(order?.environment)} />
        </Box>
    );
};

export default AppUrl;
