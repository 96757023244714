import React from 'react';
import Aksns from './AKSNS';
import Cosmos from './Cosmos';
import ServiceBus from './ServiceBus';
import WebApp from './WebApp';
import ContainerApp from './ContainerApp';
import Sql from './Sql';

const ConfigurationModal = (props) => {
    const { userSelectedResource, handleDrawerAction, order, secondApprover, handleSecondOwner } = props;

    const component = () => {
        const { configurationComponentCode } = userSelectedResource;
        switch (configurationComponentCode) {
            case 'AKSNS': {
                return <Aksns resource={userSelectedResource} handleDrawerAction={handleDrawerAction} order={order} />;
            }
            case 'AZCOSMOSMONGO':
            case 'AZCOSMOSNOSQL': {
                return <Cosmos resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            case 'AZSERVICEBUS': {
                return <ServiceBus resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            case 'AZSQLPAAS': {
                return (
                    <Sql
                        resource={userSelectedResource}
                        handleDrawerAction={handleDrawerAction}
                        handleSecondApprover={handleSecondOwner}
                        secondApprover={secondApprover}
                        order={order}
                    />
                );
            }
            case 'WEBAPP': {
                return <WebApp resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            case 'CONTAINERAPP': {
                return <ContainerApp resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            default: {
                return null;
            }
        }
    };

    return component();
};

export default ConfigurationModal;
