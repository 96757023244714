import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload } from 'pages/provisioning/virtual-service/store';

export const Department = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    // const appRegionReq = useRecoilValue(isAppRegionRequired);
    const [options, setOptions] = React.useState([]);

    const departmentApi = useMutation(() => axios.get(`${endPoints.virtualService.departments}`));

    const setDepartment = (department, departmentDetails) => {
        setOrder({
            ...order,
            department,
            departmentDetails,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (region) => options.find((option) => region === option.value);

    const handleOnSelectChange = (selected) => setDepartment(selected.value, selected);

    React.useEffect(() => {
        if (departmentApi?.isSuccess && departmentApi?.data?.data) {
            setOptions(departmentApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        } else {
            departmentApi?.mutate();
        }
    }, [departmentApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.department) {
            setOrder({ ...order, department: options?.[0].value });
        }
        if (options && options?.length && order?.department) {
            const department = isExistsInOptions(order?.department);
            setOrder({ ...order, deparment: department?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.sector) {
            resetOptions();
            departmentApi?.mutate();
        }
    }, [order?.sector]);

    React.useEffect(() => {
        if (!order?.department && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.deparment, order?.isTouched]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="department"
                    dataTestId="app-department-autocomplete"
                    fullWidth
                    label="Department *"
                    value={order?.department || null}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    disabled={departmentApi.isLoading}
                    isLoading={departmentApi.isLoading}
                    isRequired={isTouched}
                />
            </Box>
        </>
    );
};

export default React.memo(Department);
