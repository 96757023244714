import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import {
    orderPayload,
    validVSForm,
    selectedVSService,
    validationStatus,
    vsRandomValue,
    isDuplicateCustomServiceName,
} from 'pages/provisioning/virtual-service/store';
import InfoImg from 'components/info-img';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';

export const ServiceName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [helperText, setHelperText] = React.useState(false);
    const [virtualServiceName, setVirtualServiceName] = React.useState(null);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [validationErrorStatus] = useRecoilState(validationStatus);
    const [invalidServiceName, setInvalidServiceName] = React.useState(false);
    const [randomValue] = useRecoilState(vsRandomValue);
    // const [validServiceName, setValidCustomDomainName] = React.useState('');
    const [isDuplicate, setIsDuplicate] = useRecoilState(isDuplicateCustomServiceName);

    // const debouncedServiceName = useDebounce(order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName, 500);

    const checkDuplicate = (value) => {
        const serviceNameList = [];
        order?.aksVirtualService?.forEach((item, index) => {
            if (index !== selectedVS) {
                serviceNameList.push(item?.aksService?.[0]?.serviceName);
            }
        });
        if (value) {
            return serviceNameList?.indexOf(value) > -1;
        }
        return false;
    };
    const handleValueChange = (serviceName) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                if (index === selectedVS) {
                    return {
                        ...item,
                        aksService: [
                            {
                                ...item?.aksService[0],
                                serviceName,
                            },
                            ...item?.aksService.slice(1),
                        ],
                    };
                }
                return item;
            }),
        }));
    };
    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName !== undefined &&
            !/^[a-zA-Z0-9_-]{3,255}$/.test(order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName)
        ) {
            setInvalidServiceName(true);
            setHelperText(true);
            setValidForm({ ...validForm, isInvalidServiceName: true });
        } else if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName !== undefined &&
            checkDuplicate(order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName)
        ) {
            setIsDuplicate(true);
            setInvalidServiceName(true);
            setValidForm({ ...validForm, isInvalidServiceName: true });
            setHelperText(true);
        } else {
            setIsDuplicate(false);
            setInvalidServiceName(false);
            setHelperText(false);
            setValidForm({ ...validForm, isInvalidServiceName: false });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName]);

    React.useEffect(() => {
        if (!order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName && order?.isConfigureTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName, order?.isConfigureTouched]);

    const onServiceChange = (vsName) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                if (index === selectedVS) {
                    return {
                        ...item,
                        virtualServiceName: vsName,
                    };
                }
                return item;
            }),
        }));
    };

    React.useEffect(() => {
        onServiceChange(
            !invalidServiceName && order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName
                ? `${order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName}-${randomValue}-vs`
                : ''
        );
    }, [invalidServiceName, order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName]);
    const getVSValidationStatus = () => {
        const failedServices = [];
        if (validationErrorStatus?.length > 0) {
            validationErrorStatus?.filter((error) => {
                return error?.services?.map((service) => {
                    if (
                        service?.name === order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName &&
                        error?.virtualServiceName === virtualServiceName
                    ) {
                        failedServices.push(service);
                    }
                    return null;
                });
            });
            return failedServices;
        }
        return false;
    };
    const getErrorMessage = () => {
        const validationError = getVSValidationStatus();
        if (helperText && !isDuplicate) {
            return 'Service name should have aplha-numeric value with min 3 chars, max 255 chars and can contain underscore(_) and hyphen(-) as special chars.';
        }
        if (helperText && isDuplicate) {
            return 'Service name already used in another Virtual service, please use unique one.';
        }
        if (!helperText && validationError?.length > 0) {
            return (
                <>
                    {validationError?.map((error, errorIndex) => (
                        <React.Fragment key={`error-${errorIndex}`}>
                            {error?.msg?.map((msg, index) => (
                                <p key={index}>{msg}</p>
                            ))}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return '';
    };

    React.useEffect(() => {
        const selectedVirtualService = {
            vsName: order?.aksVirtualService?.[selectedVS]?.virtualServiceName,
        };

        Object.freeze(selectedVirtualService);
        setVirtualServiceName(selectedVirtualService?.vsName);
    }, []);
    const validateInput = () => {
        if (
            (isTouched && order?.isConfigureTouched && !order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName) ||
            helperText ||
            getErrorMessage()
        ) {
            return 'validate';
        }
        return '';
    };
    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="service-name-box">
            <CssTextField
                size="small"
                fullWidth
                autoComplete="off"
                label="Service Name *"
                error={!!(helperText || isTouched || getErrorMessage())}
                helperText={getErrorMessage()}
                value={order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName || ''}
                onChange={(e) => handleValueChange(e?.target?.value)}
                className={validateInput()}
            />
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Service Name:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            A service in AKS acts as an abstract to expose an application running on a set of pods, providing stable IP
                                            addresses, load balancing, and service discovery.
                                        </Typography>{' '}
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle} />
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            1) It can have alpha-numeric value with max 255 chars.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            2) It can contain only hyphen(-) as special char.
                                        </Typography>
                                    </Grid>{' '}
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            pep-cmp-dev-order
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};
export default React.memo(ServiceName);
