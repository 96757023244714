import { generateConfigPayload, isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI002O/utils';
import { platformManaged, selectedEnv as selectedEnvConst } from './constant';
import { isSecondaryGrpOwner } from './checkValidFields';

export const getPreviewPayload = ({ order, secondaryApproverValue }) => {
    const components = order?.resourceMgmt?.[selectedEnvConst]?.components;
    return {
        'is-external': true,
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
        'application-service': order?.appProfile,
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'manager-id': order?.appOwnerDetails?.gpid,
        'business-owner-id': order?.businessContactDetails?.gpid,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        'budget-code': order?.budgetCode,
        'data-classification': order?.dataClassification,
        ...(order?.appSupportMailId && {
            'app-support-mail-id': order?.appSupportMailId,
        }),
        ...(order?.certificateOwnerOneDetails &&
            order?.certificateOwnerTwoDetails && {
                'certificate-owner-one': order?.certificateOwnerOneDetails?.gpid,
                'certificate-owner-two': order?.certificateOwnerTwoDetails?.gpid,
            }),
        ...(isSecondaryGrpOwner({ order }) &&
            secondaryApproverValue?.value?.groupOwnerDetails?.gpid && {
                'active-directory-group-owner-id': secondaryApproverValue?.value?.groupOwnerDetails?.gpid,
            }),

        'environment-configurations': [
            {
                environment: order?.environment,
                ...(order?.appUrl && { 'application-url': order?.appUrl }),
                ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-groups': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
                ...(order?.environment?.toLowerCase() !== 'production' &&
                    order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-groups': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
                components: generateConfigPayload(components),
            },
        ],
    };
};

export const getCostCalcPayload = ({ order, selectedEnv }) => {
    const components = order?.resourceMgmt?.[selectedEnv]?.components;
    return {
        isExternal: true,
        catalogServiceCode: order?.catalogCode,
        components: generateConfigPayload(components),
        environment: order?.environment,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { applicationRegion: order?.region }),
        platformManaged,
    };
};

export const getOrderPayload = ({ order, secondaryApproverValue }) => {
    const components = order?.resourceMgmt?.[selectedEnvConst]?.components;
    return {
        'is-external': true,
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
        'application-service': order?.appProfile,
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'manager-id': order?.appOwnerDetails?.gpid,
        'business-owner-id': order?.businessContactDetails?.gpid,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        'budget-code': order?.budgetCode,
        'data-classification': order?.dataClassification,
        ...(order?.appSupportMailId && {
            'app-support-mail-id': order?.appSupportMailId,
        }),
        ...(order?.certificateOwnerOneDetails &&
            order?.certificateOwnerTwoDetails && {
                'certificate-owner-one': order?.certificateOwnerOneDetails?.gpid,
                'certificate-owner-two': order?.certificateOwnerTwoDetails?.gpid,
            }),
        ...(isSecondaryGrpOwner({ order }) &&
            secondaryApproverValue?.value?.groupOwnerDetails?.gpid && {
                'active-directory-group-owner-id': secondaryApproverValue?.value?.groupOwnerDetails?.gpid,
            }),

        'environment-configurations': [
            {
                environment: order?.environment,
                ...(order?.appUrl && { 'application-url': order?.appUrl }),
                ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-groups': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
                ...(order?.environment?.toLowerCase() !== 'production' &&
                    order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-groups': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
                components: generateConfigPayload(components),
            },
        ],
    };
};

export const getResubmitOrderPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;

    const environmentDetails = catalog?.environmentConfigurations[0];
    const components = environmentDetails?.components;

    return {
        ...(catalog?.projectId && { 'project-id': catalog?.projectId }),
        ...(catalog?.businessUnit && { 'business-unit': catalog?.businessUnit }),
        sector: catalog?.sector,
        ...(['cgf']?.includes(catalog?.sector?.toLowerCase()) && { division: catalog?.division }),
        ...(isShowAppRegionField({ order: { sector: catalog?.sector, catalogCode: order?.catalogCode } }) && { 'application-region': catalog?.region }),
        'budget-code': catalog?.costCenter,
        ...(catalog?.billingInformation && { 'billing-information': catalog?.billingInformation }),
        'is-external': true,
        'data-classification': catalog?.dataClassification,
        'manager-id': order?.manager?.id,
        'application-service': catalog?.applicationService,
        'application-name': catalog?.applicationName,
        'app-short-name': catalog?.applicationShortName,
        'business-owner-id': catalog?.businessOwnerId,
        ...(catalog?.readerAdGroup && catalog?.readerAdGroup?.length && { 'reader-ad-group': catalog?.readerAdGroup }),
        ...(catalog?.contributorAdGroup && catalog?.contributorAdGroup?.length && { 'contributor-ad-group': catalog?.contributorAdGroup }),

        ...(catalog?.appSupportMailId && {
            'app-support-mail-id': catalog?.appSupportMailId,
        }),
        ...(catalog?.certificateOwnerOne &&
            catalog?.certificateOwnerTwo && {
                'certificate-owner-one': catalog?.certificateOwnerOne,
                'certificate-owner-two': catalog?.certificateOwnerTwo,
            }),
        ...(order?.activeDirectoryGroupOwner && { 'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id }),
        'environment-configurations': [
            {
                environment: environmentDetails?.environmentDisplayName,
                ...(environmentDetails?.applicationUrl && { 'application-url': environmentDetails?.applicationUrl }),
                ...(environmentDetails?.contributorAdGroups &&
                    environmentDetails?.contributorAdGroups?.length && {
                        'contributor-ad-groups': environmentDetails?.contributorAdGroups?.map((z) => z),
                    }),
                ...(environmentDetails?.environmentDisplayName?.toLowerCase() !== 'production' &&
                    environmentDetails?.readerAdGroups &&
                    environmentDetails?.readerAdGroups?.length && { 'reader-ad-groups': environmentDetails?.readerAdGroups?.map((z) => z) }),
                components: components.map((component) => ({
                    ...component,
                })),
            },
        ],
    };
};
