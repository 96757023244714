import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Typography,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Drawer,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import axios from 'axios';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { PEPVIGIL } from 'pages/dlops-dashboard/utils/constant';
import { Item, StyledTableHeader, StyledTableCell, style } from './style';
import { ProgressIcon } from '../style';
import CopyUrl from '../../../assets/images/dlopsImages/copy-solid (2).svg';
import ErrorIcon from '../../../assets/images/dlopsImages/Drift-errorIcon.svg';
import AccessToken from '../components/accessToken/accessToken';

const ModelDriftImages = () => {
    const { getAccessToken, handleTokenError } = AccessToken();
    const [driftDataSet, setDriftDataSet] = useState([]);
    const [batchSize] = useState(51);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(batchSize - 1);
    const [inputError, setInputError] = useState('');
    const history = useHistory();
    const [hasMore, setHasMore] = useState(true);
    const [inputBlobPath, updateInputBlobPath] = useState('');
    const [arizeModelUrl, setArizeModelUrl] = useState('');
    const [arizeModelName, setArizeModelName] = useState('');
    const [saveAttemptCount, setSaveAttemptCount] = useState(0);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [isEmailPopupOpen, setEmailPopupOpen] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [emailError, setEmailError] = useState('');

    let receivedData;

    const showPredictionValues = () => {
        const row = localStorage.getItem('viewDriftDataSetVersion');
        const driftDataSetStorage = JSON.parse(row);
        return driftDataSetStorage?.tags.some((tag) => tag.key === 'model_type' && tag.value === 'classification');
    };

    const columns = [
        {
            name: 'Image',
        },
        { name: 'Image URL' },
        {
            name: 'Action',
        },
    ];

    if (showPredictionValues()) {
        columns.splice(1, 0, { name: 'Predicted Label' });
        columns.splice(2, 0, { name: 'Predicted Score' });
    }

    const fetchData = async (_startIndex, _endIndex) => {
        const driftDataSetString = localStorage.getItem('viewDriftDataSetVersion');
        const driftDataSetStorage = JSON.parse(driftDataSetString);
        const payload = {
            model_id: driftDataSetStorage?.run_id || driftDataSetStorage.model_id,
        };
        try {
            const accessToken = await getAccessToken();
            const response = await axios.post(endPoints.driftImages(_startIndex, _endIndex), payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            receivedData = response?.data?.data?.drifted_images;
            if (receivedData && receivedData.length > 0) {
                setArizeModelUrl(response?.data?.data?.arize_model_url);
                setArizeModelName(response?.data?.data?.arize_model_name);
                setDriftDataSet((prevDriftDataSet) => [...prevDriftDataSet, ...receivedData]);
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            handleTokenError(error, endPoints.driftImages(_startIndex, _endIndex), 'post', payload);
        } finally {
            setInitialLoading(false);
            setScrollLoading(false);
            setInitialLoadComplete(true);
        }
    };

    const fetchMoreData = () => {
        const newStartIndex = driftDataSet.length;
        const newEndIndex = newStartIndex + batchSize - 1;
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
        setScrollLoading(true);
        fetchData(newStartIndex, newEndIndex);
    };

    const handleInputChange = (event) => {
        const blobContainerPath = event.target.value;
        updateInputBlobPath(blobContainerPath);
        if (!blobContainerPath.trim()) {
            setInputError('Please enter a valid blob container path');
        } else {
            setInputError('');
        }
    };

    useEffect(() => {
        fetchData(startIndex, endIndex);
    }, []);

    const handleCopyClick = (imageURL) => {
        const tempInput = document.createElement('input');
        tempInput.value = imageURL;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999);

        document.execCommand('copy');

        document.body.removeChild(tempInput);

        toast('Image URL copied to clipboard', {
            position: 'top-right',
            autoClose: 3000,
        });
    };

    const handleRemove = (row) => {
        setDriftDataSet((prevData) => prevData.filter((item) => item.pointId !== row.pointId));
    };

    const handleNavigation = () => {
        history.push('/pepvigil-model-versions');
    };

    const saveImagesToBlob = async () => {
        try {
            if (!inputBlobPath.trim()) {
                setInputError('Please enter a blob container path');
                return;
            }
            const imagesUrl = driftDataSet.map((item) => item.linkToData);
            const payload = {
                destination_blob_path: `${inputBlobPath}`,
                images_url: imagesUrl,
            };
            const accessToken = await getAccessToken();

            await axios.post(endPoints.saveToBlobStorage, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // toast.success('Saved to Blob Storage Successfully', {
            //     position: 'top-right',
            //     autoClose: 3000,
            // });
            setEmailPopupOpen(true);
            setSaveAttemptCount(saveAttemptCount + 1);
            if (saveAttemptCount >= 1) {
                setDisplayPopup(true);
            }
            setDisplayPopup(false);
        } catch (error) {
            setDisplayPopup(false);
            const errorMessage = error.response?.data?.message || 'Error saving images to blob';
            setInputError(errorMessage);
            setEmailPopupOpen(false);
        }
    };
    const handlePopupClose = (choice) => {
        setEmailPopupOpen(false);
        setDrawerOpen(false);
        if (choice === 'yes') {
            setDrawerOpen(true);
        }
        setEmail('');
        setEmailList([]);
        setEmailError('');
    };

    const handleAddEmail = () => {
        if (!email.trim()) {
            setEmailError('Please enter an email address.');
            return;
        }
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        if (!email.endsWith('@pepsico.com')) {
            setEmailError('Email must belong to the domain pepsico.com.');
            return;
        }

        if (emailList.includes(email.trim())) {
            setEmailError('This email is already in the list.');
            return;
        }

        setEmailList([...emailList, email.trim()]);
        setEmail('');
        setEmailError('');
    };

    const handleRemoveEmail = (emailToRemove) => {
        setEmailList(emailList.filter((e) => e !== emailToRemove));
        setEmailError('');
    };

    const handleSaveClick = async () => {
        if (saveAttemptCount === 0) {
            await saveImagesToBlob();
        } else {
            setDisplayPopup(true);
        }
        setSaveAttemptCount(saveAttemptCount + 1);
    };

    const closePopup = () => {
        setDisplayPopup(false);
    };

    const showPredictionLabelScoreValues = (row) => {
        const localDriftStorage = localStorage.getItem('viewDriftDataSetVersion');
        const driftDataSetStorage = JSON.parse(localDriftStorage);
        return driftDataSetStorage?.tags.some((tag) => tag.key === 'model_type' && tag.value === 'classification') ? (
            <>
                <StyledTableCell sx={{ color: '#000000' }}>{row?.predictionLabel}</StyledTableCell>
                <StyledTableCell sx={{ color: '#000000' }}>{row?.predictionScore}</StyledTableCell>
            </>
        ) : null;
    };

    const createSupportLink = (message) => {
        const supportText = 'support';
        const supportEmail = 'dl-pepeedlops@pepsico.com';

        if (message.includes(supportText)) {
            const parts = message.split(supportText);
            return (
                <>
                    {parts[0]}
                    <a href={`mailto:${supportEmail}`} style={{ color: '#D40020' }}>
                        {supportText}
                    </a>
                    {parts[1]}
                </>
            );
        }
        return message;
    };
    const handleSubmit = async () => {
        const driftDataSetString = localStorage.getItem('viewDriftDataSetVersion');
        const driftData = JSON.parse(driftDataSetString);
        const projectName = driftData?.tags?.find((tag) => tag.key === 'project_name')?.value || 'undefined';

        if (emailList.length === 0) {
            emailError('Please add at least one email address.');
            return;
        }

        const payload = {
            to_reciepients: emailList,
            subject: 'Pepvigil - Drifted images saved notification',
            plain_text: JSON.stringify({
                project_name: projectName,
                model_name: driftData.name,
                model_version: driftData.version,
                blob_path: inputBlobPath,
            }),
        };

        const accessToken = getAccessToken();
        try {
            const response = await axios.post(endPoints.notifyEmailAlert, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            toast.success(response?.data?.Response, {
                position: 'top-right',
                autoClose: 3000,
            });
            setDrawerOpen(false);
            setEmail('');
            setEmailList([]);
            setEmailError('');
            updateInputBlobPath('');
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message || 'An unexpected error occurred.';
            toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (emailError) {
            // Clear error as the user types
            setEmailError('');
        }
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>{PEPVIGIL} Dashboard</title>
            </Helmet>
            <Box style={{ marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div
                                    style={{
                                        marginLeft: '28px',
                                        marginTop: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <span>{PEPVIGIL}</span>
                                    <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                        /Model versions
                                    </span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Drift images</span>
                                </div>
                            </Item>
                        </Typography>
                    </Grid>
                </Grid>
                {driftDataSet && driftDataSet.length > 0 ? (
                    <div style={style.dataTableDriftFlexColumn}>
                        <div style={style.dataTableFlex}>
                            <div style={style.subText1B}>
                                Enter the blob container path for re-training model:{' '}
                                <a target="_blank" href={arizeModelUrl} rel="noreferrer" style={{ color: '#1378D4' }}>
                                    {arizeModelName}
                                </a>
                            </div>
                        </div>
                        <div style={style.inputdriftRow}>
                            <span style={{ color: '#D40020', verticalAlign: 'middle', marginRight: '5px', fontSize: '16px' }}>*</span>
                            <input
                                placeholder="Enter the blob container path"
                                value={inputBlobPath}
                                style={{ ...style.inputSize, border: inputError ? '1px solid #D40020' : '1px solid #d9d9d9' }}
                                onChange={(e) => handleInputChange(e)}
                                onBlur={(e) => handleInputChange(e)}
                            />
                            <button type="button" style={style.buttonBg} onClick={handleSaveClick}>
                                Save to blob
                            </button>
                        </div>
                        {inputError && (
                            <Box style={{ color: '#D40020', marginTop: '-10px', marginLeft: '30px', font: 'normal normal medium 14px/18px PP Object Sans' }}>
                                <img src={ErrorIcon} alt="errorIcon" />
                                &nbsp;&nbsp;
                                {createSupportLink(inputError)}.
                            </Box>
                        )}
                    </div>
                ) : (
                    ''
                )}

                <Box
                    id="scrollableDiv"
                    style={{
                        height: '800px',
                        overflow: 'auto',
                        width: '98%',
                        fontSize: 13.5,
                        borderRadius: '0px 0px 12px 12px',
                        margin: '0px 10px 10px 10px',
                    }}
                >
                    {initialLoading && (
                        <Box sx={ProgressIcon.provisioning_wrapper}>
                            <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                            <Typography variant="body2" sx={ProgressIcon.circularProgressColor}>
                                Loading
                            </Typography>
                        </Box>
                    )}
                    <InfiniteScroll
                        dataLength={driftDataSet.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv"
                        loader={
                            scrollLoading && (
                                <Box sx={style.loading_progress}>
                                    <CircularProgress color="inherit" size={16} sx={style.loading_progress} />
                                    <Typography variant="body2" sx={style.circularProgressColor}>
                                        Loading More Data
                                    </Typography>
                                </Box>
                            )
                        }
                        endMessage={
                            initialLoadComplete && !hasMore && driftDataSet.length > 0 ? (
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            ) : null
                        }
                    >
                        {driftDataSet && driftDataSet.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                    {column.name}
                                                </StyledTableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {driftDataSet.map((row, indexs) => (
                                            <TableRow key={indexs}>
                                                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                    <img src={`${row.linkToData}`} alt="" style={style.imgSize} />
                                                </StyledTableCell>

                                                {showPredictionLabelScoreValues(row)}
                                                <StyledTableCell sx={{ color: '#000000' }}>
                                                    {row?.linkToData}{' '}
                                                    <Typography
                                                        style={{
                                                            background: '#1378D4 0% 0% no-repeat padding-box',
                                                            borderRadius: '4px',
                                                            width: '100px',
                                                            height: '32px',
                                                            display: 'flex',
                                                            paddingLeft: '15px',
                                                            paddingBottom: '10px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleCopyClick(row.linkToData)}
                                                    >
                                                        <Typography
                                                            style={{
                                                                background: 'transparent 0% 0% no-repeat padding-box',
                                                                width: '100px',
                                                                height: '30px',
                                                                opacity: 1,
                                                                padding: '0px',
                                                                color: '#FFFFFF',
                                                                textAlign: 'left',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            Copy URL
                                                            <img src={CopyUrl} alt="copyUrl" style={{ marginTop: '5px', marginLeft: '5px' }} />
                                                        </Typography>
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <DeleteIcon onClick={() => handleRemove(row)} />
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div
                                variant="body1"
                                style={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    marginTop: '300px',
                                }}
                            >
                                No drift images to display
                            </div>
                        )}
                    </InfiniteScroll>
                    {displayPopup && (
                        <Dialog open={displayPopup} onClose={closePopup}>
                            <DialogTitle>Are you sure you want to save again?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{/* Your content here */}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closePopup} className="btnCancelColor" style={{ lineHeight: '43px', color: '#1378D4' }}>
                                    Cancel
                                </Button>
                                <Button onClick={saveImagesToBlob} className="btnThemecolor">
                                    Yes, Save again!
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    <Dialog
                        open={isEmailPopupOpen}
                        onClose={() => handlePopupClose('no')}
                        sx={{
                            '& .MuiDialog-paper': {
                                width: '480px', // Set desired width
                                height: '250px', // Set desired height
                                display: 'flex', // Use flex for alignment
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center', // Center content horizontally
                                padding: '20px', // Add padding for spacing
                            },
                        }}
                    >
                        <CheckCircleIcon
                            sx={{
                                fontSize: '50px',
                                color: '#1378D4',
                                marginBottom: '8px', // Add some space below the icon
                            }}
                        />
                        <DialogTitle
                            sx={{
                                fontSize: '16px',
                                textAlign: 'center',
                                padding: '0', // Remove extra padding for alignment
                            }}
                        >
                            Drifted Images Saved
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                marginTop: '10px', // Adjust spacing
                            }}
                        >
                            Would you like an email notification with details?
                        </DialogContent>
                        <DialogActions
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '10px',
                                marginTop: '20px',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => handlePopupClose('yes')}
                                sx={{
                                    backgroundColor: '#1378D4',
                                }}
                            >
                                Yes
                            </Button>
                            <Button variant="outlined" onClick={() => handlePopupClose('no')}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* <h3>Email Notifications</h3> */}
                    <Drawer
                        anchor="right"
                        aria-hidden="true"
                        open={isDrawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                padding: '20px',
                                backgroundColor: '#f5f5f5',
                                position: 'relative',
                                height: '100%',
                            }}
                        >
                            {/* Header Section */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '90px',
                                    background: '#FFFFFF',
                                    boxShadow: '0px 0px 2px #00000029',
                                    borderRadius: '0px 0px 4px 4px',
                                    padding: '0 20px',
                                }}
                            >
                                <IconButton onClick={handlePopupClose} sx={{ color: '#000000' }}>
                                    <CloseIcon sx={{ fontSize: '36px' }} />
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#021D49',
                                        flexGrow: 1,
                                        textAlign: 'center',
                                        fontSize: '21px',
                                        fontWeight: 'medium',
                                    }}
                                >
                                    Email notification list for Saved Drifted Images
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column', // Stack elements vertically
                                    gap: '10px', // Space between elements
                                    marginTop: '30px',
                                }}
                            >
                                {/* Label */}
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#021D49',
                                        fontWeight: 'medium',
                                        fontSize: '16px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Notify Email Alert
                                </Typography>

                                {/* Text Field and Add Button */}
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <TextField
                                        id="filled-email-input"
                                        label="ADD EMAIL ACCOUNT"
                                        type="email"
                                        autoComplete="current-email"
                                        variant="filled"
                                        value={email}
                                        onChange={handleEmailChange}
                                        sx={{
                                            width: '400px',
                                            background: '#F2F2F2',
                                            boxShadow: '0px 1px 1px #00000065',
                                            '& .MuiFilledInput-root': {
                                                '&:before': {
                                                    borderBottomColor: '#888888',
                                                },
                                                '&:hover:not(.Mui-disabled):before': {
                                                    borderBottomColor: '#888888',
                                                },
                                                '&:after': {
                                                    borderBottomColor: '#888888',
                                                },
                                                '&.Mui-focused:before': {
                                                    borderBottomColor: '#888888',
                                                },
                                                '&.Mui-focused': {
                                                    backgroundColor: 'transparent',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#000000',
                                                fontSize: '15px',
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: '#888888',
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: '121px',
                                            height: '36px',
                                            backgroundColor: '#1378D4',
                                        }}
                                        onClick={handleAddEmail}
                                    >
                                        Add
                                    </Button>
                                </Box>

                                {/* Error Message */}
                                {emailError && (
                                    <Typography
                                        sx={{
                                            color: '#FF0000',
                                            fontSize: '14px',
                                            paddingTop: '5px',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        {emailError}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ marginBottom: '0px', marginTop: '20px', marginLeft: '0px' }}>
                                {emailList &&
                                    emailList.map((emailAddress, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginBottom: '10px',
                                                background: emailAddress.includes(email) ? '#E0F7FA' : '#FFFFFF',
                                                borderRadius: '4px',
                                                width: '502px',
                                                height: 'auto', // Adjust height to accommodate content
                                                border: '1px solid #D6D6D6',
                                                padding: '10px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap', // Allow wrapping of content
                                                    gap: '8px', // Add spacing between elements
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#000000',
                                                        fontSize: '14px',
                                                        wordBreak: 'break-word', // Break long email addresses
                                                        maxWidth: 'calc(100% - 110px)', // Leave space for the button
                                                    }}
                                                >
                                                    {emailAddress}
                                                </Typography>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        minWidth: '94px',
                                                        height: '36px',
                                                        border: '1px solid #1378D4',
                                                        color: '#1378D4',
                                                        borderRadius: '4px',
                                                        flexShrink: 0, // Prevent the button from shrinking
                                                    }}
                                                    onClick={() => handleRemoveEmail(emailAddress)}
                                                    disabled={emailAddress.length === 1}
                                                >
                                                    Remove
                                                </Button>
                                            </Box>
                                        </Box>
                                    ))}
                            </Box>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                height: '50px',
                                alignSelf: 'center',
                                backgroundColor: '#1378D4',
                                color: '#FFFFFF',
                                fontSize: '16px',
                                fontWeight: 'medium',
                                width: '750px',
                                boxShadow: '0px 0px 2px #00000029',
                                borderRadius: '0px 0px 4px 4px',
                                padding: '0 20px',
                            }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Drawer>
                </Box>
            </Box>
        </ErrorBoundary>
    );
};

export default ModelDriftImages;
