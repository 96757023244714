import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { OrderPayload } from 'pages/sso-okta/store';
import { CssTextField } from 'components/select';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { searchHardcodedGpids } from '../../utils/formValidation';
import { HARD_CODED_GPIDS } from '../../utils/constant';

const AppOwnerSsoSearch = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [searched, setSearched] = React.useState(false);
    const [gpidValidation, setGpidValidation] = React.useState(null);
    const appOwnerApi = useMutation(() => axios.get(`${endPoints?.ssoOkta?.appOwnerSso}?key=${ccInputValue}`));
    const appOwnerPostApi = useMutation((payload) => axios.get(`${endPoints?.ssoOkta?.appOwnerSso}?key=${payload}`));

    const setHCode = (appOwner, appOwnerDetails) => {
        setOrder({
            ...order,
            appOwner,
            appOwnerDetails,
        });
    };

    React.useEffect(() => {
        if (!order?.appOwner && !order?.appOwnerDetails) {
            setOptions([]);
        }
    }, [order?.appOwner, order?.appOwnerDetails]);

    const resetOptions = () => {
        setOptions([]);
        setHCode(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            const searchResult = searchHardcodedGpids(ccInputValue, HARD_CODED_GPIDS);
            if (searchResult.isValid) {
                setGpidValidation(null);
                fetch();
            } else {
                setGpidValidation(searchResult);
                resetOptions();
            }
        }
    };
    const isExistsInOptions = (appownerValue) => options.find((option) => appownerValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.appOwner && order?.appOwnerDetails) {
            setOrder({ ...order, appOwner: options[0]?.label, appOwnerDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.appOwnerDetails && !order?.appOwner) {
            const appOwerObj = isExistsInOptions(order?.appOwnerDetails?.name);
            setOrder({ ...order, appOwner: options[0]?.label, appOwnerDetails: appOwerObj });
        }
    }, [options]);
    React.useEffect(() => {
        if (appOwnerApi?.data?.data) {
            const combinedValue = appOwnerApi?.data?.data?.reduce((result, app) => {
                if (HARD_CODED_GPIDS.some((entry) => entry?.gpid === app?.gpid)) {
                    result.push({
                        label: `${app.gpid} - ${app.name}`,
                        gpid: `${app.gpid}`,
                        name: `${app.name}`,
                        email: `${app.email}`,
                    });
                }

                return result;
            }, []);
            setOptions(combinedValue);
        }
    }, [appOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (appOwnerPostApi?.data?.data) {
            const combinedValue = appOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((id) => id?.gpid === value?.gpid));
            setHCode(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.appOwner && order?.appOwnerDetails) {
            appOwnerPostApi.mutate(order?.appOwnerDetails?.gpid);
        }
    }, [order?.appOwner, order?.appOwnerDetails]);

    React.useEffect(() => {
        if (!order?.appOwner && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appOwner, order?.isTouched]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.appOwner) || isTouched) {
            return 'validate';
        }
        return '';
    };

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0 && gpidValidation === null) {
            text = 'No results found';
        } else if (gpidValidation?.isValid === false) {
            text = 'You will be able to search these SSO GPIDS 71001190, 80970694 & 80231631 and refer for tool tip if you need more information';
        }
        return text;
    };
    return (
        <>
            <Box display="flex" alignItems="center" gap={1} data-testid="app-owner-box">
                <Autocomplete
                    data-testid="app-owner-autocomplete"
                    key="appOwnerDetails"
                    size="small"
                    loading={appOwnerApi?.isLoading}
                    noOptionsText={getNoOptionText()}
                    value={order?.appOwner || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                    }}
                    className={validateInput()}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    onBlur={() => {
                        if (!value || !order?.appOwner) {
                            setSearched(false);
                            setOptions([]);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(false);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                        }
                    }}
                    //    filterOptions={filterOptions}

                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                        // return _results
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="SSO Owner Approvals *"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={{ cursor: 'pointer', color: '#0047BA' }}
                                            onClick={() => {
                                                handleSearch();
                                                setSearched(true);
                                            }}
                                            data-testid="search-btn"
                                        />
                                    </>
                                ),
                            }}
                            fullWidth
                        />
                    )}
                />
                <HelpImg
                    title={
                        <Box sx={{ wordBreak: 'break-word' }}>
                            You are entitle to create 24-30 tokes per day per instance(per server). If this limit has been crossed your application can not be
                            migrate to Production. Hence, for Production onboarding we need to validate token generation count for your application in ITE from
                            SSO team. You will be able to search these SSO GPIDS 71001190, 80970694 & 80231631. Please select and submit Order and get approvals
                            from them in order to proceed for your production onboarding.
                        </Box>
                    }
                    maxWidth="700px"
                />
            </Box>
        </>
    );
};

export default React.memo(AppOwnerSsoSearch);
