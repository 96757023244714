import { DateTime } from 'luxon';
import { scope } from 'utils/scope';
import { scopedCatalogs } from './scopedCatalogs';

const readOnlyRole = 'CMPUserReadOnly';

export const getLoggedUserClaims = () => {
    const loggedUser = localStorage.getItem('okta-token-storage');
    return loggedUser ? JSON.parse(loggedUser) : undefined;
};

export const getLoggedUserGPID = () => {
    const userClaims = getLoggedUserClaims();
    return userClaims?.accessToken?.claims?.gpid ?? '';
};

export const getUserRoles = () => {
    if (getLoggedUserClaims()) {
        try {
            const {
                accessToken: { claims },
            } = getLoggedUserClaims();
            return claims?.pepAppPEPComDevRoles?.length !== 0 ? claims?.pepAppPEPComDevRoles : [readOnlyRole];
        } catch (e) {
            // eslint-disable-next-line
            console.log('Exception during fetching claims from accesstoken', e);
        }
        return [readOnlyRole];
    }
    return [readOnlyRole];
};
export const getScopes = () => {
    return JSON.parse(localStorage.getItem('pages'));
};
export const isCMPAdmin = () => {
    // return getUserRoles().includes(roles[0]);
    return getScopes()?.includes(scope[2]);
};
export const isPlatformUser = () => {
    return getScopes()?.includes(scope[10]);
};
export const isCMPBetaUser = () => {
    return getScopes()?.includes(scope[7]);
};
export const isRIEnabled = () => {
    return process.env.REACT_APP_ENABLE_RI === 'true';
};

export const getUserScopes = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const { scp } = JSON.parse(Buffer.from(accessToken?.split('.')[1], 'base64')?.toString());
        return scp || [];
    }
    return [];
};

export const getLoggedUserFullName = () => {
    if (getLoggedUserClaims()) {
        const { idToken } = getLoggedUserClaims();
        return `${idToken?.claims.FirstName} ${idToken?.claims.LastName}`;
    }
    return 'FirstName LastName';
};

export const getAccessToken = (field) => {
    if (getLoggedUserClaims()) {
        const { accessToken } = getLoggedUserClaims();
        if (field) return accessToken?.[field];
        return accessToken ? accessToken?.accessToken : undefined;
    }
    return null;
};

export function LoggedUser() {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')) || { accessToken: undefined };
    return {
        FullName() {
            if (accessToken) {
                return `${accessToken?.claims.FirstName} ${accessToken?.claims.LastName}`;
            }
            return 'UserName';
        },
    };
}

export function checkIsRIPage(segments, location) {
    const pathSegments = location?.pathname?.split('/');
    return segments.some((segment) => pathSegments.includes(segment));
}

export const getEnv = (env) => {
    const envVars = process.env;
    return envVars[env];
};

export const camelToTitle = (camelCase) => {
    const result = camelCase?.replace(/([A-Z])/g, ' $1');
    const snakeCase = result?.charAt(0).toUpperCase() + result?.slice(1);
    return snakeCase.replace(/_/g, ' ');
};
export const convertToTitleCase = (str) => {
    if (!str) return undefined;
    let result = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    result = result.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    result = result.replace(/_/g, ' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
};
export const camelToKebab = (s) => {
    return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

export const transformKeysCamelToKebab = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(transformKeysCamelToKebab);
    }
    if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelToKebab(key)]: transformKeysCamelToKebab(obj[key]),
            }),
            {}
        );
    }
    return obj;
};
export const titleCase = (str) =>
    str
        ?.toLowerCase()
        ?.split(' ')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');

export const toDateTime = (datetime) => DateTime.fromISO(datetime).toFormat('dd/LLL/yyyy HH:mm');

export const getPageTitle = (url) => {
    if (url.includes('/vm-provisioning')) {
        return 'VM Provisioning';
    }
    if (url.includes('/sql-paas-provisioning')) {
        return 'SQL PaaS Provisioning';
    }
    if (url.includes('/orders/me')) {
        return 'My Orders';
    }
    if (url.includes('/orders')) {
        return 'All Orders';
    }
    if (url.includes('/approvals')) {
        return 'Approvals';
    }
    return 'Home | Cloud Market Place';
};

export const parseQueryString = (url = '') => {
    // parse query string into key/value pairs and return as object
    const query = {};
    // eslint-disable-next-line
    url.replace(/^.*\?/, '').replace(/([^\=]+)\=([^\&]*)\&?/g, function (match, key, value) {
        query[key] = decodeURIComponent(value);
        return '';
    });
    return query;
};

export function isReadOnlyUser() {
    if (getEnv('REACT_APP_IS_EDITABLE') === 'true') return false;
    return getUserRoles().includes(readOnlyRole);
}

export function allowedServices(catalogCode = null) {
    let result = false;
    if (scopedCatalogs.includes(catalogCode)) {
        result = isCMPAdmin() || isCMPBetaUser();
    } else {
        result = true;
    }
    return result;
}

export function LoggedUserFirstName() {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')) || { accessToken: undefined };
    return {
        FirstName() {
            if (accessToken) {
                return `${accessToken?.claims.FirstName}`;
            }
            return 'UserFirstName';
        },
    };
}
export const getRetentionPeriod = (retentionPeriod, retentionUnit) => {
    let retention = '';
    if (retentionUnit === 'In Days') {
        retention = `${retentionPeriod / 86400000} Day(s)`;
    } else {
        let seconds = Math.floor(retentionPeriod / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        seconds %= 60;
        minutes = seconds >= 30 ? minutes + 1 : minutes;
        minutes %= 60;
        hours %= 24;
        retention = `${hours}:${minutes} Hrs`;
    }
    return retention;
};
export const currencySymbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
};
export const isValueExistsInOptions = (options, selectedValue, searchBy) => options.find((option) => selectedValue === option?.[searchBy]);

export const navigateToWithPageReload = (url) => {
    window.location.href = url;
};
export const mapLableValue = (label, value, data) => {
    if (data) {
        return data?.map((app) => ({ label: `${app[label]}`, value: `${app[value]}`, data: app }));
    }
    return [];
};

export const exportToJson = (objectData = {}, fileName = 'config.json') => {
    const contentType = 'application/json;charset=utf-8;';
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(objectData, null, 2))}`;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
};

export const generateQueryString = (obj, parentKey = null) => {
    const keyValuePairs = [];
    const transformKey = (key) => key?.replace(/([a-z])([A-Z])/g, '$1_$2')?.toLowerCase();

    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const transformedKey = transformKey(key);

        const fullKey = parentKey ? `${parentKey}[${transformedKey}]` : transformedKey;

        if (Array.isArray(value)) {
            value.forEach((item) => {
                keyValuePairs.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(item)}`);
            });
        } else if (typeof value === 'object' && value !== null) {
            keyValuePairs.push(generateQueryString(value, fullKey));
        } else if (value !== undefined && value !== null && value !== '') {
            keyValuePairs.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`);
        }
    });

    return keyValuePairs.join('&');
};

export const filterMenuItemsByRole = (menuItems = [], userScopes = [], excludedItems = ['My Persona']) => {
    const hasAccess = (item) => {
        return item?.allowedScopes.length === 0 || item?.allowedScopes?.some((sc) => userScopes?.includes(sc));
    };
    const filterItems = (items) => {
        return items?.reduce((filtered, item) => {
            if (!excludedItems.includes(item?.name) && hasAccess(item)) {
                const newItem = { ...item };
                if (newItem?.children) {
                    newItem.children = filterItems(newItem?.children);
                }
                filtered.push(newItem);
            }
            return filtered;
        }, []);
    };

    return filterItems(menuItems);
};

export const mapExtensionsToMimeTypes = (extensions) => {
    const mimeTypeMapping = {
        '.pdf': ['application/pdf'],
        '.ppt': ['application/vnd.ms-powerpoint'],
        '.pptx': ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
        '.jpeg': ['image/jpeg'],
        '.jpg': ['image/jpeg'],
        '.png': ['image/png'],
        // Add more mappings as needed
    };

    const acceptObject = {};
    extensions?.forEach((ext) => {
        const mimeTypes = mimeTypeMapping[ext];
        if (mimeTypes) {
            mimeTypes?.forEach((mimeType) => {
                if (!acceptObject[mimeType]) {
                    acceptObject[mimeType] = [];
                }
                acceptObject[mimeType].push(ext);
            });
        }
    });
    return acceptObject;
};

/**
 * Converts Megabytes (MB) to Bytes.
 *
 * @param {number} mb - The size in Megabytes.
 * @return {number} The size in Bytes.
 */
export const convertMBtoBytes = (mb) => {
    return mb * 1024 * 1024;
};
export const convertBytesToMB = (bytes, decimals = 2) => {
    if (bytes === 0) return '0';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / (k * k);
    return `${parseFloat(sizeInMB?.toFixed(dm))}`;
};

export const converObjectToBlob = (obj) => {
    const json = JSON.stringify(obj);
    const blobObj = new Blob([json], {
        type: 'application/json',
    });
    return blobObj;
};
export const getValueFromKey = (searchKey, keyValuePairs) => {
    return keyValuePairs[searchKey] ? keyValuePairs[searchKey] : searchKey;
};
export const sliceLastChar = (text) => (text?.lastIndexOf('^') > -1 ? text?.slice(0, text?.lastIndexOf('^')) : text);
export const windowsVM = ['AZWINVM', 'AZWINVM2016', 'AZWINVM2019', 'AZWINVM2022'];
export const linuxVM = [
    'AZRHELVM',
    'AZRHEL8LVM',
    'AZRHEL7LVM',
    'AZRHEL8VM',
    'AZRHEL7VM',
    'AZOEL7VM',
    'AZSLES15VM',
    'AZSLES12VM',
    'AZOEL8VM',
    'AZPGTSAPSLES15VM',
    'AZPGTSAPSLESHANADB',
];
export const sqlPaaS = ['AZSQLPAAS'];
export const oracleIaaS = ['AZRHELORA19C', 'AZOELORA19C'];
export const sqlIaaS = ['AZWINSQLIAAS2019', 'AZWINSQLIAAS'];
export const storageBlog = ['AZSTORAGE'];
export const aksNS = ['AZAKSNS'];
export const NSGRule = ['AZNSGRULE'];
export const alb = ['AZLOADBAL'];
export const oelVM = ['AZOEL7VM', 'AZOEL8VM'];
export const oelOracle = ['AZOELORA19C'];
export const azKeyVault = ['AZKEYVAULT'];

export const formattedDate = (datetime) => {
    const number = datetime;
    const date = new Date(number);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const timestamp = datetime; // Replace with your actual timestamp

    const creationDate = new Date(timestamp);

    const option = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    const formattedCreationTime = creationDate.toLocaleString('en-US', option);

    const formattedLastModified = `${date.toLocaleString('en-US', options)} ${formattedCreationTime}`;

    return formattedLastModified;
};

export const extractFqdn = (url) => {
    try {
        const parsedUrl = new URL(url);
        const { hostname } = parsedUrl;
        const fqdn = hostname?.replace(/^www\./, ''); // Remove 'www.' if present
        return fqdn;
    } catch (e) {
        return null;
    }
};

export const showAdminTiles = []; // ['RI003', 'RI003A'];
export const showPlatformAdminTiles = ['AZNEWINTAPPGW']; // ['RI003', 'RI003A'];
