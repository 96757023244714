import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isAppUrlValid } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { validateLoginLogoutURL } from 'pages/sso-okta/utils/index';

export const LogoutUrl = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [applicationUrlValid, setApplicationUrlVaild] = useRecoilState(isAppUrlValid);
    const [helperText, setHelperText] = React.useState(false);

    const setApplicationUrl = (logoutUrl = null) => {
        setOrder({
            ...order,
            logoutUrl,
        });
    };

    React.useEffect(() => {
        const { isValid } = validateLoginLogoutURL(order?.logoutUrl, order?.environment, order?.requestType);
        if (order?.logoutUrl && order?.logoutUrl !== '' && !isValid) {
            setHelperText('Logout URL is invalid.');
            setApplicationUrlVaild(false);
        } else if (order?.logoutUrl === '') {
            setApplicationUrlVaild(false);
            setHelperText(false);
        } else if (order?.logoutUrl && isValid) {
            setHelperText(false);
            setApplicationUrlVaild(true);
        }
        if (order?.applicationUrl && order?.logoutUrl && order?.applicationUrl === order?.logoutUrl) {
            setHelperText(
                <>
                    <Box>Your login and logout URL&apos;s should not be same.</Box>
                    <Box>Example: Logout URL: https://cloud.dev.mypepsico.com/</Box>
                </>
            );
            setApplicationUrlVaild(false);
        }
    }, [order?.logoutUrl, order?.environment, order?.applicationUrl, order?.requestType]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.logoutUrl) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.logoutUrl && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.logoutUrl, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-url-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Logout URL *"
                error={!!helperText}
                helperText={helperText && <span>{helperText}</span>}
                value={order?.logoutUrl || ''}
                onChange={(e) => setApplicationUrl(e.target.value)}
                className={validateInput()}
                data-testid="AppURLText"
                onBlur={() => {
                    if (order?.recipientUrl === null && order?.destinationUrl === null && order?.logoutUrl && applicationUrlValid) {
                        setOrder({
                            ...order,
                            recipientUrl: order?.logoutUrl,
                            destinationUrl: order?.logoutUrl,
                            audienceURI: order?.audienceURI,
                        });
                    }
                }}
            />
            <HelpImg
                title={
                    <Box sx={{ whiteSpace: 'pre' }}>
                        <Box>It is also known as Single sign-on URL where the SAML assertion is sent with a HTTP POST.</Box>
                        <Box>This is often referred to as the SAML Assertion Consumer Service (ACS) URL for your application.</Box>
                        <Box sx={{ fontWeight: 'bold' }}>Logout URL should be in this format - https://cloud.dev.mypepsico.com/</Box>
                    </Box>
                }
                maxWidth="600px"
            />
        </Box>
    );
};

export default LogoutUrl;
