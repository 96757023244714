import { styled } from '@mui/material/styles';
import { Radio, RadioGroup, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const SsoOktaStyles = {
    form: {
        radioLabel: { color: '#666666', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal', width: '100%' },
    },
    ssoOkta_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    addBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
    resubmitBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { fontSize: '30px', fontWeight: 500, fontFamily: 'Stolzl', color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
    },
    removeBtn: {
        cursor: 'pointer',
        color: '#F4273B',
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    roleWrapper: {
        label: { fontSize: '16px', fontWeight: 500, color: '#0047BA' },
    },
    Dialog: {
        title: { fontFamily: 'Stolzl', fontSize: '20px', fontWeight: 'bold', color: '#000000', paddingTop: '10px', paddingBottom: '5px' },
        msgDiv: { fontFamily: 'Roboto', fontSize: '13px', fontWeight: 'normal', padding: '5px 11px' },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '360px',
            height: '145px',
            paddingTop: '15px',
            paddingBottom: '5px',
        },
        IconColor: '#C02223',
        icon: { fill: '#C02223', fontSize: '60px' },
        AzIcon: '#d02424',
        backBtnBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2), paddingBottom: '5px' },
        backBtn: { paddingX: '65px', textTransform: 'none' },
        AgreeBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            paddingX: '65px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                border: '2px solid #00A0DD',
                background: 'transparent',
            },
        },
    },
    accessControl: {
        select: {
            '& label.Mui-focused': {
                color: '#0047BA',
            },

            '& label.MuiInputLabel-shrink': {
                color: '#0047BA',
                padding: '0 2px',
                fontWeight: 700,
            },
        },
        chip: {
            '& svg.MuiChip-deleteIcon': {
                color: 'red',
            },
            borderRadius: '5px',
            height: '20px',
        },
        deleteIcon: { fontSize: '14px', color: '#707070' },
    },
};
export default SsoOktaStyles;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
        paddingLeft: 15,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#242424',
        fontWeight: 'normal',
        '&:first-of-type': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    paddingTop: '5px',
    height: '50px',
    '&:nth-of-type(1) td': {
        height: '60px',
        paddingTop: '15px',
    },
    '&:hover': {
        backgroundColor: '#E5F5FC',
    },
    // hide last border
    th: {
        marginLeft: 20,
    },
    '&:last-child td, &:last-child th': {},
}));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
