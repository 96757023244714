import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box, Stack } from '@mui/material';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import {
    OrderPayload,
    ArchImgUrl,
    isExpandArchDiagram,
    costEstimateState,
    isProjectIdValid,
    toggleModal,
    showPreviewOrder,
    isBusinessNameValid,
    isBudgetCodeValid,
    isAppShortNameValid,
    isAppShortNameExist,
    previewApiLoading,
    isBusinessAppValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
    previewOrderError,
    isAppUrlValid,
    // isAppContextPathValid,
    isValidappSupportMailId,
    isValidOwners,
    componentGroupsState,
    SecondaryApprover,
    isAppNameValid,
} from 'pages/reference-implementation/provisioning/RI004O/store';
import { orderSuccessPopup } from 'pages/reference-implementation/store';
import { currentLayoutScroll, riCatalogs } from 'store';
import StickyHeader from 'pages/reference-implementation/components/sticky-header';
import CostSummaryCard from 'pages/reference-implementation/components/cost-summary-card';
import ArchDialog from 'pages/reference-implementation/components/architecture-diagram/dialog';
import { SubmitForApproval } from 'pages/reference-implementation/provisioning/RI004O/components/Form/SubmitForApproval';
import { ShowCostDetails } from 'pages/reference-implementation/provisioning/RI004O/components/Form/ShowCostDetails';
// import Form from 'pages/reference-implementation/provisioning/RI003O/components';
import { OrderSuccessDialog } from 'pages/reference-implementation/components/order-success';
import PreviewOrderDetails from 'pages/reference-implementation/provisioning/RI004O/PreviewOrderDetails';
import { PreviewOrder } from 'pages/reference-implementation/provisioning/RI004O/components/Form/PreviewOrder';
import NonPrivilegedADGroupModal from 'pages/reference-implementation/provisioning/RI004O/components/Form/NonPrivilegedADGroupModal';
import PrivilegedADGroupModal from 'pages/reference-implementation/provisioning/RI004O/components/Form/PrivilegedADGroupModal';
// import InfoTooltip from 'pages/reference-implementation/components/info-tooltip';
import { SubmitForValidation } from 'pages/reference-implementation/provisioning/RI004O/components/Form/SubmitForValidation';
import { exportToJson, getAccessToken } from 'utils';
import Empty from 'components/empty';
import { selectedEnv } from 'pages/reference-implementation/provisioning/RI004O/utils/constant';
import { FAILED, RUNNING, SUCCESS } from 'utils/ValidationStatus';
import riStyle, { LeftBox, ScrollableContainer, ScrollableContent, StickyRightBox } from 'pages/reference-implementation/Style';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';
import { RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';
import { isSecondaryGrpOwner } from 'pages/reference-implementation/provisioning/RI004O/utils/checkValidFields';
import { isReadyToGetCost, isReadyToSubmitNew } from './utils/validation';
import { getPreviewPayload, getCostCalcPayload } from './utils/getPayload';
import { getpreviewDetails, getPreviewDetailsMultiRI } from './utils/preview';
import { resetCostEstimateDetails, resetOrder, formOrderObjFromOrderDetails, getComponentsFromSchema } from './utils';
import IndexForm from './IndexForm';
import transformData from './utils/transformcomponentGroup';

const RI004O = () => {
    const history = useHistory();
    const gpid = getAccessToken('claims')?.gpid;
    const currLayoutScroll = useRecoilValue(currentLayoutScroll);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [catalog, setCatalog] = useState(undefined);
    const [componentGroups, setComponentGroups] = useRecoilState(componentGroupsState);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    const [riArchImgUrl] = useRecoilState(ArchImgUrl);
    const [costEstimate, setCostEstimate] = useRecoilState(costEstimateState);
    const [isExpand, setIsExpand] = useRecoilState(isExpandArchDiagram);
    const [modal, setModal] = useRecoilState(toggleModal);
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [projectIdValid, setProjectIdValid] = useRecoilState(isProjectIdValid);
    const [businessUnitValid, setBusinessUnitValid] = useRecoilState(isBusinessNameValid);
    const [budgetCodeValid, setBudgetCodeValid] = useRecoilState(isBudgetCodeValid);
    const [appShortNameValid, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [appShortNameExist, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const [isPreviewApiLoading, setPreviewApiLoading] = useRecoilState(previewApiLoading);
    const [businessAppValid, setBusinessAppValid] = useRecoilState(isBusinessAppValid);
    const [appUrlValid, setAppUrlValid] = useRecoilState(isAppUrlValid);
    const [validApplicationSupportMail, setValidApplicationSupportMail] = useRecoilState(isValidappSupportMailId);
    const [appNameValid, setAppNameValid] = useRecoilState(isAppNameValid);
    // const appContextPathValid = useRecoilValue(isAppContextPathValid);
    const [nonPrivilegedADGroupValid, setNonPrivilegedADGroupValid] = useRecoilState(isNonPrivilegedADGroupValid);
    const [privilegedADGroupValid, setPrivilegedADGroupValid] = useRecoilState(isPrivilegedADGroupValid);
    const [isSuccessOpen, setSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [previewError, setPreviewError] = useRecoilState(previewOrderError);
    const [validOwners, resetValidOwners] = useRecoilState(isValidOwners);
    const [secondaryApproverValue, setSecondaryApprover] = useRecoilState(SecondaryApprover);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const riEndpoints = React.useMemo(() => getEndpointForCatalogCode(catalogCode), [catalogCode]);
    const checkIsMultipleResources = () => [RICatalogCode005]?.includes(catalogCode);
    const oldPreviewApi = useMutation((payload) => axios.post(riEndpoints.Jwt.previewOrder, payload));
    const previewOrderApi = useMutation((payload) => axios.get(`${riEndpoints.Jwt.internalPreviewOrder}/${payload?.orderId}`));
    const orderDetailsApi = useMutation(() => axios.get(`${endPoints.riServices.Jwt.orderDetails}/${cartItemId}`));
    const downloadApi = useMutation(() => axios.get(`${riEndpoints.Jwt.downloadTF}/${cartItemId}/ess-terraform-plan-download`));
    const CostCalculationApi = useMutation((payload) => axios.post(riEndpoints.Jwt.costCalculation, payload));

    // const programApi = useMutation((payload) => axios.post(`${endPoints.riServices.COMMON.program}`, payload));

    const handleReset = () => {
        setOrder({
            ...resetOrder,
            catalogCode,
            ...(componentGroups?.length > 0 && {
                resourceMgmt: {
                    [selectedEnv]: {
                        components: getComponentsFromSchema({ componentGroups, particularPrompt: ['mandatoryComponents'] })?.[0]?.components || [],
                    },
                },
            }),
        });
        setCostEstimate(resetCostEstimateDetails);
        setProjectIdValid(false);
        setBusinessUnitValid(true);
        setBudgetCodeValid(true);
        setShowPreview(null);
        setBusinessAppValid(true);
        setAppUrlValid(false);
        setAppShortNameVaild(false);
        setAppShortNameExist(false);
        setSuccessPopup(false);
        setNonPrivilegedADGroupValid(false);
        setPrivilegedADGroupValid(false);
        setValidApplicationSupportMail(false);
        setAppNameValid(false);
        setPreviewError({
            isPreviewError: false,
            message: '',
        });
        resetValidOwners({
            isvalidAppOwner: true,
            isvalidCertificateOwnerOne: true,
            isvalidCertificateOwnerTwo: true,
        });
        setSecondaryApprover({ selectedFieldFrom: { module: null, subModule: null }, value: { groupOwner: null, groupOwnerDetails: null } });
    };

    const getPreviewOrder = () => {
        const payload = getPreviewPayload({ order, secondaryApproverValue });
        oldPreviewApi?.mutate(payload);
    };

    const downloadConfig = () => {
        downloadApi?.mutate();
    };

    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };

    useEffect(() => {
        const data = cmpRiCatalogs?.find((item) => item?.['reference-implementation-id'] === catalogCode);
        if (data) {
            const filteredComponentGroups = data['component-groups'];
            const updatedData = transformData(filteredComponentGroups);
            setComponentGroups(updatedData);
        }
    }, [cmpRiCatalogs, catalogCode]);

    const checkCatalog = async () => {
        setPreviewApiLoading(true);
        try {
            const data = cmpRiCatalogs?.find((item) => item?.['reference-implementation-id'] === catalogCode);
            setCatalog(data);
            if (cartItemId && operation && catalogServiceId) {
                const [{ data: orderDetails }] = await Promise.all([orderDetailsApi.mutateAsync()]);
                const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails, componentGroups);
                if (operation === 'preview') {
                    const [{ data: previewOrderResponse }] = await Promise.all([previewOrderApi?.mutateAsync({ orderId: cartItemId })]);
                    setProjectIdValid(true);
                    setShowPreview('preview-order');
                    setNonPrivilegedADGroupValid(true);
                    setPrivilegedADGroupValid(true);
                    setOrder((previous) => ({
                        ...previous,
                        ...orderObj,
                        catalogCode,
                        environmentDetails: previewOrderResponse?.environmentDetails,
                        validationDetails: previewOrderResponse?.validationDetails,
                        orderId: cartItemId,
                        currentOrderStatus: orderDetails?.currentStatus?.status,
                        createdBy: orderDetails?.created?.by?.id,
                        previewOrder: checkIsMultipleResources()
                            ? getPreviewDetailsMultiRI({
                                  order: {
                                      ...order,
                                      ...orderObj,
                                      environmentDetails: previewOrderResponse?.environmentDetails,
                                  },
                              })
                            : getpreviewDetails({
                                  order: {
                                      ...order,
                                      ...orderObj,
                                      environmentDetails: previewOrderResponse?.environmentDetails,
                                  },
                              }),
                    }));

                    if (orderDetails?.activeDirectoryGroupOwner?.id) {
                        setSecondaryApprover({
                            selectedFieldFrom: {
                                module: null,
                                subModule: null,
                            },
                            value: {
                                groupOwner: `${orderDetails?.activeDirectoryGroupOwner?.id} - ${orderDetails?.activeDirectoryGroupOwner?.name}`,
                                groupOwnerDetails: {
                                    label: `${orderDetails?.activeDirectoryGroupOwner?.id} - ${orderDetails?.activeDirectoryGroupOwner?.name}`,
                                    gpid: orderDetails?.activeDirectoryGroupOwner?.id,
                                    name: orderDetails?.activeDirectoryGroupOwner?.name,
                                    email: orderDetails?.activeDirectoryGroupOwner?.emailId,
                                },
                            },
                        });
                    }
                }
                if (operation !== 'preview') {
                    setProjectIdValid(true);
                    setNonPrivilegedADGroupValid(true);
                    setPrivilegedADGroupValid(true);
                    setOrder((previous) => ({
                        ...previous,
                        ...orderObj,
                        catalogCode,
                        orderId: cartItemId,
                    }));
                    if (orderDetails?.activeDirectoryGroupOwner?.id) {
                        setSecondaryApprover({
                            selectedFieldFrom: {
                                module: null,
                                subModule: null,
                            },
                            value: {
                                groupOwner: `${orderDetails?.activeDirectoryGroupOwner?.id} - ${orderDetails?.activeDirectoryGroupOwner?.name}`,
                                groupOwnerDetails: {
                                    label: `${orderDetails?.activeDirectoryGroupOwner?.id} - ${orderDetails?.activeDirectoryGroupOwner?.name}`,
                                    gpid: orderDetails?.activeDirectoryGroupOwner?.id,
                                    name: orderDetails?.activeDirectoryGroupOwner?.name,
                                    email: orderDetails?.activeDirectoryGroupOwner?.emailId,
                                },
                            },
                        });
                    }
                }
            } else if (componentGroups?.length > 0) {
                setOrder({
                    ...order,
                    catalogCode: data?.['reference-implementation-id'],
                    resourceMgmt: {
                        [selectedEnv]: {
                            components: getComponentsFromSchema({ componentGroups, particularPrompt: ['mandatoryComponents'] })?.[0]?.components || [],
                        },
                    },
                });
            }
            setPreviewApiLoading(false);
        } catch (err) {
            setPreviewApiLoading(false);
        }
    };

    useEffect(() => {
        if (cmpRiCatalogs?.length && catalogCode) {
            handleReset();
            checkCatalog();
        }
    }, [cmpRiCatalogs, catalogCode, componentGroups]);

    useEffect(() => {
        setCostEstimate(resetCostEstimateDetails);
        if (operation === 'preview') {
            setShowPreview('preview-order');
        } else {
            setShowPreview(null);
        }
        return () => {
            handleReset();
        };
    }, []);
    useEffect(() => {
        const formValidation = isReadyToSubmitNew({
            order,
            businessAppValid,
            projectIdValid,
            businessUnitValid,
            budgetCodeValid,
            appShortNameValid,
            appShortNameExist,
            nonPrivilegedADGroupValid,
            privilegedADGroupValid,
            appNameValid,
            appUrlValid,
            validApplicationSupportMail,
            validOwners,
        });
        if (formValidation?.isValid) {
            getPreviewOrder();
        }
        if (!isSecondaryGrpOwner({ order })) {
            setSecondaryApprover({ selectedFieldFrom: { module: null, subModule: null }, value: { groupOwner: null, groupOwnerDetails: null } });
        }
    }, [
        order?.appProfile,
        order?.sector,
        order?.division,
        order?.region,
        order?.environment,
        order?.appUrl,
        order?.appOwner,
        order?.budgetCode,
        order?.dataClassification,
        order?.applicationName,
        order?.appShortName,
        order?.businessContact,
        order?.businessContactDetails,
        order?.certificateOwnerOne,
        order?.certificateOwnerTwo,
        order?.nonPrivilegedADGroup,
        order?.privilegedADGroup,
        order?.nonPrivilegedADGroupOwner,
        order?.nonPrivilegedADGroupOwnerDetails,
        order?.privilegedADGroupOwner,
        order?.privilegedADGroupOwnerDetails,
        order?.resourceMgmt,
        order?.privilegedADGroupList,
        order?.nonPrivilegedADGroupList,
        nonPrivilegedADGroupValid,
        privilegedADGroupValid,
        appUrlValid,
        order?.siteName,
    ]);

    useEffect(() => {
        if (oldPreviewApi?.isSuccess) {
            setOrder({
                ...order,
                environmentDetails: oldPreviewApi?.data?.data,
                prePreviewOrder: checkIsMultipleResources()
                    ? getPreviewDetailsMultiRI({
                          order: {
                              ...order,
                              environmentDetails: oldPreviewApi?.data?.data,
                          },
                      })
                    : getpreviewDetails({
                          order: {
                              ...order,
                              environmentDetails: oldPreviewApi?.data?.data,
                          },
                      }),
            });
        }
    }, [oldPreviewApi?.isSuccess]);

    useEffect(() => {
        if (downloadApi?.isSuccess && downloadApi?.data?.data) {
            const fileName = `${order?.applicationName?.replaceAll(' ', '')}_${order?.environment?.replaceAll(' ', '')}_plan`;
            exportToJson(downloadApi?.data?.data, fileName);
        }
    }, [downloadApi?.isSuccess]);
    useEffect(() => {
        if (showPreview === null && (operation === undefined || operation === 'edit' || operation === 'preview')) {
            if (oldPreviewApi?.isError) {
                const message = Array.isArray(oldPreviewApi.error) ? oldPreviewApi.error : [oldPreviewApi?.error?.message];
                setPreviewError({ ...previewError, isPreviewError: true, message });
            } else {
                setPreviewError({ ...previewError, isPreviewError: false, message: '' });
            }
        }
    }, [oldPreviewApi?.isError, showPreview, operation]);
    const getHeaderButton = () => {
        if (showPreview === 'pre-preview-order') {
            return <SubmitForValidation isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />;
        }
        if (showPreview === 'preview-order') {
            return gpid === order?.createdBy && order?.currentOrderStatus === 'Validation' && [SUCCESS]?.includes(order?.validationDetails?.status) ? (
                <SubmitForApproval isBgApiLoading={previewOrderApi?.isLoading} />
            ) : null;
        }
        return null;
    };
    const isShowBackBtn = () => {
        if (showPreview === 'pre-preview-order') {
            return true;
        }
        if (showPreview === 'preview-order') {
            return [RUNNING, SUCCESS, FAILED]?.includes(order?.validationDetails?.status);
        }
        return false;
    };

    const handleBackBtn = () => {
        if (showPreview === 'pre-preview-order') {
            setShowPreview(null);
        }
        if (showPreview === 'preview-order') {
            if (history?.location?.state?.from?.orderPage) {
                history.push(history?.location?.state?.from?.orderPage);
            } else {
                history.push(`/orders?seld_order_id=${cartItemId}&seld_catalog_service_code=${catalogCode}`);
            }
        }
    };
    useEffect(() => {
        toast.dismiss();
    }, [
        order?.projectDetails,
        order?.applicationName,
        order?.businessUnit,
        order?.budgetCode,
        order?.sector,
        order?.division,
        order?.dataClassification,
        order?.environment,
    ]);
    const showIcon = () => {
        let displayIcon = true;
        if (showPreview === 'preview-order' || (operation === 'edit' && showPreview === 'pre-preview-order')) {
            displayIcon = false;
        }
        return displayIcon;
    };

    React.useEffect(() => {
        if (order?.createdBy?.id && order?.createdBy?.id !== gpid) {
            toast.error('You can only edit orders created by you, Please select your order.');
            history.push('/orders');
        } else {
            toast.dismiss();
        }
    }, [order]);

    React.useEffect(() => {
        if (CostCalculationApi?.data?.data) {
            setCostEstimate(CostCalculationApi?.data?.data);
        }
    }, [CostCalculationApi?.isSuccess]);
    React.useEffect(() => {
        setCostEstimate(resetCostEstimateDetails);
        const formValidation = isReadyToGetCost(order);
        if (formValidation?.isValid) {
            const getCostPayload = getCostCalcPayload({ order, selectedEnv });
            CostCalculationApi?.mutate(getCostPayload);
        }
    }, [order?.isExternal?.toString(), order?.environment, order?.sector, order?.division, order?.applicationRegion, order?.resourceMgmt]);
    return (
        <>
            <Helmet>
                <title>{`${catalog?.['reference-implementation-id']} Provisioning - CMP`}</title>
            </Helmet>
            {(isPreviewApiLoading || previewOrderApi?.isLoading || orderDetailsApi?.isLoading) && (
                <Stack sx={riStyle?.emptyStack}>
                    <Empty isLoading color="#000000" msg="Loading..." />
                </Stack>
            )}
            {!(isPreviewApiLoading || previewOrderApi?.isLoading || orderDetailsApi?.isLoading) && (
                <>
                    {catalog && (
                        <StickyHeader
                            title={
                                showPreview === 'preview-order' || (operation === 'edit' && showPreview === 'pre-preview-order')
                                    ? `Order ID - ${cartItemId}`
                                    : catalog?.name
                            }
                            iconPath={catalog?.iconPath}
                            back={isShowBackBtn()}
                            handleBackBtn={() => handleBackBtn()}
                            showCartBtn={showPreview}
                            cartBtn={getHeaderButton()}
                            showInfoIcon={showIcon()}
                            // infoIcon={
                            //     <InfoTooltip
                            //         title={
                            //             <>
                            //                 <a href={catalog?.['help-link']} target="_blank" rel="noreferrer">
                            //                     Click here for further details
                            //                 </a>
                            //             </>
                            //         }
                            //     />
                            // }
                        />
                    )}
                    <ScrollableContainer className="ri-layout" sx={{ display: !showPreview ? 'flex' : 'none' }}>
                        <LeftBox>
                            <ScrollableContent>
                                <IndexForm />
                            </ScrollableContent>
                        </LeftBox>
                        <StickyRightBox>
                            <Box sx={currLayoutScroll > 64 ? riStyle?.fixedSummary : {}}>
                                <CostSummaryCard
                                    catalog={catalog}
                                    costEstimate={costEstimate}
                                    validateButton={<SubmitForValidation isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />}
                                    previewButton={<PreviewOrder isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />}
                                    handleCostInfoBtn={handleCostInfoBtn}
                                />
                            </Box>
                        </StickyRightBox>
                    </ScrollableContainer>
                    {showPreview === 'preview-order' && order?.previewOrder && (
                        <PreviewOrderDetails
                            order={order}
                            details={order?.previewOrder}
                            onDownloadConfig={downloadConfig}
                            isDownloading={downloadApi?.isLoading}
                        />
                    )}
                    {showPreview === 'pre-preview-order' && order?.prePreviewOrder && (
                        <PreviewOrderDetails
                            order={order}
                            details={order?.prePreviewOrder}
                            onDownloadConfig={downloadConfig}
                            isDownloading={downloadApi?.isLoading}
                        />
                    )}
                    <ArchDialog isOpen={isExpand} setOpen={setIsExpand} imgUrl={riArchImgUrl} />
                    <ShowCostDetails />
                    <OrderSuccessDialog isOpen={isSuccessOpen} reset={handleReset} redirectUrl="/" />
                    <NonPrivilegedADGroupModal />
                    <PrivilegedADGroupModal />
                </>
            )}
        </>
    );
};
export default React.memo(RI004O);
