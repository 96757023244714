/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Chip, CircularProgress, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import SsoOktaStyles from 'pages/sso-okta/style';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Select, { CssTextField } from 'components/select';
import HelpImg from 'pages/provisioning/_components/helpImg';
import CloseIcon from '@mui/icons-material/Close';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ApplicationAccessControl = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const [helperText, setHelperText] = React.useState(false);

    const accessRestrictionApi = useMutation(() => axios.get(`${endPoints?.ssoOkta?.accessRestriction}/${order?.environment}`));

    const setApplicationAccessControl = (accessRestrictions) => {
        setOrder({
            ...order,
            accessRestrictions,
        });
    };

    const handleOnSelectChange = (selected) => {
        const hasValue = selected?.some((app) => app?.value === 'PEPSICO_THIRDPARTY' || app?.value === 'PEPSICO_ROBOTS');
        const otherSelections = selected?.filter((app) => app?.value !== 'PEPSICO_THIRDPARTY' && app?.value !== 'PEPSICO_ROBOTS');

        if (hasValue && otherSelections?.length === 0) {
            setHelperText(true);
        } else {
            setHelperText(false);
        }
        setApplicationAccessControl(selected?.map((app) => app?.value));
    };

    React.useEffect(() => {
        if (accessRestrictionApi?.data?.data) {
            setOptions(accessRestrictionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.value, data: app })));
        }
    }, [accessRestrictionApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.environment) {
            accessRestrictionApi.mutate();
        }
    }, [order?.environment]);

    React.useEffect(() => {
        if (!order.accessRestrictions?.length && order?.isTouched) setIsTouched(true);
    }, [order?.accessRestrictions, order?.isTouched]);

    const getAccessControlValues = (val) => {
        const res = val && val?.length && val?.map((V) => options?.filter((opt) => opt?.value === V));
        const flattened = res && res?.length && res?.reduce((a, b) => a?.concat(b));
        return flattened?.length > 0 ? flattened : [];
    };

    const validateInput = () => {
        if (isTouched) {
            return order?.accessRestrictions === '' ||
                order?.accessRestrictions === null ||
                order?.accessRestrictions === undefined ||
                order?.accessRestrictions?.length === 0 ||
                order?.accessRestrictions === 0
                ? 'validate'
                : '';
        }
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={SsoOktaStyles.stack}>
            <Select
                data-testid="application-access-control"
                className={validateInput()}
                isRequired={isTouched}
                multiple
                size="small"
                key="application-access-control"
                label="Application Access Control *"
                value={getAccessControlValues(order?.accessRestrictions) || []}
                options={options}
                onChange={(event, newValue) => {
                    handleOnSelectChange(newValue);
                }}
                fullWidth
                sx={SsoOktaStyles.accessControl.select}
                isLoading={false}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option?.label}
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option?.label}
                            {...getTagProps({ index })}
                            style={SsoOktaStyles.accessControl.chip}
                            deleteIcon={<CloseIcon style={SsoOktaStyles.accessControl.deleteIcon} />}
                        />
                    ))
                }
                disabled={accessRestrictionApi.isLoading || order?.environment === null}
                renderInput={(params) => (
                    <CssTextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Application Access Control *"
                        error={helperText}
                        helperText={helperText && <span>Please select atleast one of the remaining options.</span>}
                        InputProps={{
                            ...params?.InputProps,
                            endAdornment: (
                                <>
                                    {accessRestrictionApi?.isLoading && <CircularProgress color="inherit" size={16} />}
                                    {params?.InputProps?.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <HelpImg
                title={
                    <Box sx={{ whiteSpace: 'pre' }}>
                        <Box>
                            Manage your application access by selecting the type of PepsiCo options based on your application architecture and data
                            clarification.
                        </Box>
                        <Box>You can also be able to go with multi-selection.</Box>
                    </Box>
                }
                maxWidth="700px"
            />
        </Stack>
    );
};

export default ApplicationAccessControl;
