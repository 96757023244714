import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import toast from 'react-hot-toast';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHistory } from 'react-router-dom';
import { PEPVIGIL } from 'pages/dlops-dashboard/utils/constant';
import ErrorFallback from 'components/error-fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { Item, StyledTableCell, ProgressIcon } from '../style';
import AccessToken from '../components/accessToken/accessToken';
import TeamMemberDrawer from './createTeamMembers';

const CreateNewModelTags = () => {
    const { getAccessToken } = AccessToken();
    const [addtags, setAddTags] = useState([]);
    const history = useHistory();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteOpenPopUp, setDeletePopUp] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [errors, setErrors] = useState({});
    const [openPopup, setOpenPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showAccessFields, setShowAccessFields] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [openDrawer, setOpenDrawer] = useState(false);
    const [tags, setTags] = useState([
        {
            name: 'BaseLine Datasource',
            mkey: 'baseline_datasource',
            tooltipInfo: 'A URL that provides access to a CSV file containing details about the training dataset used for the model',
            mvalue: '',
        },
        {
            name: 'Baseline Datasource Version',
            mkey: 'baseline_datasource_version',
            tooltipInfo:
                'The version number of the baseline datasource, indicating the specific iteration or update of the CSV file containing the training dataset details',
            mvalue: '',
        },
        {
            name: 'Storage Account',
            mkey: 'storage_account',
            tooltipInfo:
                'An identifier for the cloud storage account used to store data and files associated with the project, including models, datasets, and other resources',
            mvalue: '',
        },
        {
            name: 'Market',
            mkey: 'market',
            tooltipInfo:
                'The specific market or environment in which the model is being deployed for use, indicating the target audience or application context',
            mvalue: '',
        },
        {
            name: 'Container Name',
            mkey: 'container_name',
            tooltipInfo: 'The name of the storage container used to organize and manage data and files within a cloud storage account',
            mvalue: '',
        },
        { name: 'Framework', mkey: 'framework', tooltipInfo: 'The software framework or library used to develop and implement the model', mvalue: '' },
        {
            name: 'Drift Distance Threshold',
            mkey: 'drift_distance_threshold',
            tooltipInfo:
                'Set the threshold value for the drift monitor. If the drift distance exceeds this value, the monitor will trigger. The default value as per Arize is 2.',
            mvalue: 2,
        },
    ]);

    useEffect(() => {
        const savedDefaultTags = localStorage.getItem('createDefaulttags');
        const savedNewlyAddedTags = localStorage.getItem('newlyAddedtags');

        if (savedDefaultTags) {
            setTags(JSON.parse(savedDefaultTags));
        }

        if (savedNewlyAddedTags) {
            setAddTags(JSON.parse(savedNewlyAddedTags));
        }
    }, []);

    const handleAddNewRow = () => {
        setAddTags([...addtags, { name: '', type: '', value: '', checked: false }]);
    };
    const handleClosePopup = () => {
        setOpenPopup(false);
        setShowAccessFields(false);
        if (isSuccess) {
            localStorage.removeItem('create_project');
            localStorage.removeItem('modelFormData');
            localStorage.removeItem('createNewtags');
            history.push('/pepvigil-projects');
        }
    };
    const handleCancel = () => {
        history.push('/pepvigil-projects');
    };
    const handleBack = () => {
        history.push('/pepvigil-new-modelCreation');
    };
    const handleInputChange = (index, field, value) => {
        const updatedTags = [...tags];
        updatedTags[index][field] = value;
        setTags(updatedTags);

        // Clear error if input is filled
        if (value) {
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors[index]; // Remove the error for this index
                return newErrors;
            });
        }
    };
    const handleNavigation = () => {
        history.push('/pepvigil-projects');
    };
    const handleInputTags = (index, field, value) => {
        const updatedRows = addtags.map((row, i) => (i === index ? { ...row, [field]: value } : row));
        setAddTags(updatedRows);
    };
    const handleRowCheck = (index) => {
        const updatedRows = addtags.map((row, i) => (i === index ? { ...row, isChecked: !row.isChecked } : row));
        setAddTags(updatedRows);
    };

    const handleDeleteRow = (index) => {
        setDeleteIndex(index);
        setDeletePopUp(true);
    };
    const deleteRow = (index) => {
        const updatedRows = addtags.filter((_, i) => i !== index);
        setAddTags(updatedRows);
    };
    const handleCancelDelete = () => {
        setDeletePopUp(false);
    };
    const handleDeleteAllSelected = () => {
        setDeletePopUp(true);
        setDeleteIndex('SelectedAll');
    };
    const handleSelectAll = (event) => {
        const { checked } = event.target;
        setAllSelected(checked);
        setIsAllChecked(checked);
        setAddTags(addtags.map((row) => ({ ...row, checked, isChecked: checked })));
    };
    const handleConfirmDelete = () => {
        if (deleteIndex === 'SelectedAll') {
            setAddTags(addtags.filter((row) => !row.isChecked));
        } else {
            deleteRow(deleteIndex);
        }
        setDeletePopUp(false);
        setAllSelected(false);
        setIsAllChecked(false);
    };
    // // Helper function to validate URLs
    const isValidURL = (url) => {
        const pattern = /^https:\/\/[a-zA-Z0-9_-]+\.blob\.core\.windows\.net\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_.-]+)*\/[a-zA-Z0-9_.-]+\.[a-z]+$/;
        return pattern.test(url);
    };
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                    Loading
                </Typography>
            </Box>
        );
    }

    const handleValidation = () => {
        let formIsValid = true;
        const newErrors = {};

        tags.forEach((tag, index) => {
            switch (tag.mkey) {
                case 'baseline_datasource':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = 'Baseline datasource is required.';
                    } else if (!isValidURL(tag.mvalue)) {
                        formIsValid = false;
                        newErrors[index] = 'Please enter a valid URL.';
                    }
                    break;

                case 'baseline_datasource_version':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = 'Baseline datasource version is required.';
                    } else if (!/^\d+(\.\d+)?$/.test(tag.mvalue)) {
                        formIsValid = false;
                        newErrors[index] = 'Please enter a valid number or decimal.';
                    }
                    break;
                case 'desc_version':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = 'Description version is required.';
                    }
                    break;
                case 'description_model':
                case 'storage_account':
                case 'market':
                case 'container_name':
                case 'framework':
                    if (!tag.mvalue) {
                        formIsValid = false;
                        newErrors[index] = `${tag.mkey.replace(/_/g, ' ')} is required.`;
                    }
                    break;
                case 'drift_distance_threshold':
                    if (Number.isNaN(tag.mvalue) || tag.mvalue < 0.5 || tag.mvalue > 5 || !/^\d+(\.\d+)?$/.test(tag.mvalue)) {
                        newErrors[index] = 'Please enter a number between 0.5 and less than 5';
                    }
                    break;

                default:
                    break;
            }
        });

        addtags.forEach((tag, index) => {
            if (!tag.name) {
                formIsValid = false;
                newErrors[`add_${index}_name`] = 'Name is required.';
            }
            if (!tag.value) {
                formIsValid = false;
                newErrors[`add_${index}_value`] = 'Value is required.';
            }
        });

        // Update the errors state
        setErrors(newErrors);

        return formIsValid;
    };

    const saveNewProjectCreation = async (accessTokenPromise, payload) => {
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.post(endPoints.submitCreateNewProject, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            });
            return response?.data;
        } catch (error) {
            return error;
        }
    };
    const handleSaveTags = async () => {
        if (handleValidation()) {
            localStorage.setItem('createDefaulttags', JSON.stringify(tags));
            localStorage.setItem('newlyAddedtags', JSON.stringify(addtags));
            toast.success('Meta Data Tags Saved Successfully', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };
    const handleSubmit = async () => {
        if (handleValidation()) {
            // Proceed with the submission
            const tagsToSave = tags.map(({ mkey, mvalue }) => ({ mkey, mvalue }));
            localStorage.setItem('createNewtags', JSON.stringify(tagsToSave));

            const createProject = JSON.parse(localStorage.getItem('create_project')) || {};
            const modelFormData = JSON.parse(localStorage.getItem('modelFormData')) || {};
            const tagsFromStorage = JSON.parse(localStorage.getItem('createNewtags')) || [];
            const projectNameTag = {
                mkey: 'project_name',
                mvalue: createProject.project_name,
            };
            const addNewtagsToSave = addtags.map(({ name, value }) => ({
                mkey: name,
                mvalue: value,
            }));
            const payload = {
                project_name: createProject.project_name || '',
                new_project: createProject.new_project || false,
                project_desc: createProject.project_desc || '',
                project_createdby: createProject.project_createdby || '',
                project_createdby_user_details: createProject.project_createdby_user_details,
                model_name: modelFormData.model_name || '',
                model_version: modelFormData.model_version || '',
                model_desc: modelFormData.model_desc || '',
                model_createdby: modelFormData.model_createdby || '',
                model_createdby_user_details: modelFormData.model_createdby_user_details,
                model_current_stage: modelFormData.model_current_stage,
                tags: [...tagsFromStorage, ...modelFormData.tags, projectNameTag, ...addNewtagsToSave],
            };
            try {
                setLoading(true);
                const response = await saveNewProjectCreation(getAccessToken(), payload);

                if (response && response.Response === 'Success') {
                    setPopupMessage(
                        <>
                            Project onboarded successfully.
                            {/* To access the DLops project, please reach out to our DLOps dedicated team at{' '}
                            <a href="mailto:dl-pepeedlops@pepsico.com" style={{ color: '#1378D4' }}>
                                dl-pepeedlops@pepsico.com
                            </a>
                            . */}
                        </>
                    );
                    setIsSuccess(true);
                } else {
                    setPopupMessage('Failed to create project.');
                    setIsSuccess(false);
                }
                setLoading(false);
                setOpenPopup(true);
            } catch (error) {
                setPopupMessage('Error saving data.');
                setIsSuccess(false);
                setLoading(false);
                setOpenPopup(true);
            }
        }
    };
    const handleAddTeamMembers = () => {
        setOpenDrawer(true);
        setOpenPopup(false);
    };
    const handleDrawerSubmit = () => {
        // You can handle any logic after submitting the drawer form
        setIsSuccess(true);
        history.push('/pepvigil-projects');
        // setPopupMessage('Team member added successfully!');
    };
    const handleDrawerClose = () => {
        setOpenDrawer(false);
        history.push('/pepvigil-projects');
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {' '}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        <Item>
                            <div
                                style={{
                                    marginLeft: '28px',
                                    marginTop: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                <span>{PEPVIGIL}</span>
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                    /Projects
                                </span>
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleBack()}>
                                    /Create New Project
                                </span>
                                <span aria-hidden="true" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleBack()}>
                                    /Create New Model
                                </span>
                                <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Create New Model Tags</span>
                            </div>
                        </Item>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    height: '70px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Typography sx={{ fontSize: '18px', fontWeight: 'medium', color: '#000000' }}>Create New Model Tags</Typography>
            </Box>
            <Dialog
                open={openPopup}
                onClose={handleClosePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: 600,
                        height: 'auto',
                        padding: '20px',
                        borderRadius: '8px',
                    },
                }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        {/* Conditionally render the success icon and popupMessage only if showAccessFields is false */}
                        {!showAccessFields && (
                            <>
                                {isSuccess && (
                                    <CheckCircleIcon
                                        sx={{
                                            fontSize: '50px',
                                            color: '#1378D4',
                                            marginBottom: '0px',
                                        }}
                                    />
                                )}
                                <DialogTitle
                                    id="alert-dialog-title"
                                    sx={{
                                        marginBottom: '20px',
                                        fontSize: '16px',
                                        color: isSuccess ? 'black' : 'red', // Show title in red if failed
                                    }}
                                >
                                    {isSuccess ? 'Success' : 'Failed'}
                                </DialogTitle>
                                <Typography
                                    sx={{
                                        color: isSuccess ? 'black' : 'red', // Error message in red if failed
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {popupMessage}
                                </Typography>
                                <Typography sx={{ marginTop: '20px', fontSize: '16px' }}>Do you want to give project access to the team members?</Typography>

                                {/* Yes/No buttons */}
                                <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                    <Button variant="contained" onClick={handleAddTeamMembers} sx={{ backgroundColor: '#1378D4' }}>
                                        Yes
                                    </Button>
                                    <Button variant="outlined" onClick={handleClosePopup}>
                                        No
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
            <TeamMemberDrawer open={openDrawer} onClose={handleDrawerClose} onSubmit={handleDrawerSubmit} sx={{ width: '90%' }} />
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                            borderBottom: '1px solid #1378D4',
                            width: '1400px',
                            paddingBottom: '10px',
                        }}
                    >
                        <Typography
                            sx={{
                                marginRight: '10px',
                                fontSize: '16px',
                                fontFamily: 'Object Sans',
                                color: '#000000',
                                fontWeight: 'bold',
                            }}
                        >
                            Meta Data Tags
                        </Typography>
                        <Box sx={{ display: 'flex', marginLeft: 'auto', gap: '10px' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#1378D4',
                                    width: '139px',
                                    height: '36px',
                                    borderRadius: '4px',
                                }}
                                onClick={handleAddNewRow}
                            >
                                Add New Tag
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#1378D4',
                                    width: '139px',
                                    height: '36px',
                                    borderRadius: '4px',
                                }}
                                onClick={handleSaveTags}
                            >
                                Save Tags
                            </Button>
                            {allSelected && addtags.length >= 1 && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#D40020',
                                        borderRadius: '4px',
                                        width: '187px',
                                    }}
                                    onClick={handleDeleteAllSelected}
                                >
                                    Delete All Selected
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ width: '40px', padding: '4px 8px' }}>
                                    <Checkbox checked={isAllChecked} onChange={handleSelectAll} />
                                </StyledTableCell>
                                <StyledTableCell sx={{ width: '200px', padding: '12px 12px', fontWeight: 'bold' }}>Tag Name</StyledTableCell>
                                <StyledTableCell sx={{ width: '100px', padding: '12px 12px', fontWeight: 'bold' }}>Value</StyledTableCell>
                                <StyledTableCell sx={{ width: '100px', padding: '12px 12px', fontWeight: 'bold' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tags.map((row, index) => (
                                <TableRow key={index} sx={{ height: '40px' }}>
                                    <StyledTableCell sx={{ width: '40px', padding: '12px 12px' }} />
                                    <StyledTableCell sx={{ padding: '12px 12px', width: '200px' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {row.mkey !== 'drift_distance_threshold' ? (
                                                <Typography sx={{ color: '#E4080A', marginRight: '4px' }}>*</Typography>
                                            ) : null}
                                            {row.name}
                                            {row.tooltipInfo && (
                                                <Tooltip title={row.tooltipInfo} arrow>
                                                    <InfoOutlinedIcon
                                                        sx={{
                                                            fontSize: '16px',
                                                            color: '#1378D4',
                                                            marginLeft: '8px',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: '12px 12px', width: '100px' }}>
                                        <TextField
                                            value={row.mvalue}
                                            onChange={(e) => handleInputChange(index, 'mvalue', e.target.value)}
                                            variant="filled"
                                            fullWidth={row.mkey === 'baseline_datasource'}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    background: '#FFFFFF',
                                                    height: '32px',
                                                    padding: '4px',
                                                },
                                                width: row.mkey === 'baseline_datasource' ? '500px' : '200px',
                                                border: `1px solid ${errors[index] ? 'red' : '#1378D4'}`,
                                                borderRadius: '4px',
                                            }}
                                            inputProps={{
                                                style: { padding: '4px' },
                                            }}
                                        />
                                        {errors[index] && (
                                            <Typography sx={{ color: '#FF0000', fontSize: '12px', marginTop: '4px' }}>{errors[index]}</Typography>
                                        )}
                                        {row.mkey === 'baseline_datasource' && (
                                            <Typography sx={{ fontSize: '10px', color: 'grey', marginTop: '4px' }}>
                                                Example:
                                                https://iaimlops.blob.core.windows.net/dlops-container/OC/baseline/dlops-model-oc/baseline_dlops-oc_20240425.csv
                                            </Typography>
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            ))}

                            {addtags &&
                                addtags.length > 0 &&
                                addtags.map((row, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell sx={{ width: '40px', padding: '12px 12px' }}>
                                            <Checkbox checked={row.isChecked} onChange={() => handleRowCheck(index)} />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px 12px', width: '200px' }}>
                                            <TextField
                                                value={row.name}
                                                onChange={(e) => handleInputTags(index, 'name', e.target.value)}
                                                variant="filled"
                                                sx={{
                                                    marginTop: '8px',
                                                    '& .MuiFilledInput-root': {
                                                        background: '#FFFFFF',
                                                        height: '36px',
                                                        padding: '10px',
                                                    },
                                                    width: '200px',
                                                    border: '1px solid #1378D4',
                                                    borderRadius: '4px',
                                                }}
                                                inputProps={{
                                                    style: { padding: '8px 0' },
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px 12px', width: '100px' }}>
                                            <TextField
                                                value={row.value}
                                                onChange={(e) => handleInputTags(index, 'value', e.target.value)}
                                                variant="filled"
                                                sx={{
                                                    marginTop: '8px',
                                                    '& .MuiFilledInput-root': {
                                                        background: '#FFFFFF',
                                                        height: '36px',
                                                        padding: '10px',
                                                    },
                                                    width: '200px',
                                                    border: '1px solid #1378D4',
                                                    borderRadius: '4px',
                                                }}
                                                inputProps={{
                                                    style: { padding: '8px 0' },
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ width: '100px', padding: '12px 12px' }}>
                                            {row.isChecked && (
                                                <Typography
                                                    onClick={() => handleDeleteRow(index)}
                                                    sx={{ color: '#1378D4', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                    Delete
                                                </Typography>
                                            )}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button variant="contained" sx={{ backgroundColor: '#1378D4', marginTop: '50px' }} onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ marginTop: '50px' }} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" sx={{ backgroundColor: '#1378D4', marginTop: '50px', marginLeft: '500px' }} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={deleteOpenPopUp}
                onClose={handleCancelDelete}
                PaperProps={{
                    sx: {
                        width: '480px',
                        height: '200px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 0px 6px #00000074',
                        borderRadius: '4px',
                        padding: '16px',
                    },
                }}
            >
                <DialogTitle sx={{ padding: '8px 16px', fontSize: '18px' }}>Confirm Delete</DialogTitle>
                <DialogContent sx={{ padding: '8px 16px' }}>
                    <DialogContentText sx={{ fontSize: '16px', color: '#212121' }}>Are you sure you want to delete the selected model tags?</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', padding: '8px 16px', marginLeft: '20px' }}>
                    <Button
                        onClick={handleConfirmDelete}
                        color="primary"
                        sx={{ background: '#1378D4 0% 0% no-repeat padding-box', width: '118px', height: '53px', color: 'white' }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={handleCancelDelete}
                        color="primary"
                        sx={{
                            width: '116px',
                            height: '53px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '1px solid #707070',
                            borderRadius: '6px',
                            color: '#000000',
                        }}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </ErrorBoundary>
    );
};

export default CreateNewModelTags;
