import React from 'react';
import { Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select, { CssTextField } from 'components/select';
import { orderPayload, certCreationRequired, customDomainFieldsDiasbled, isValidCostCenter } from 'pages/provisioning/virtual-service/store';
import InfoImg from 'components/info-img';

const filter = createFilterOptions();

export const CostCenter = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [value, setValue] = React.useState(order?.costCenterDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.costCenter || '');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const [isRequired] = useRecoilState(certCreationRequired);
    const [isDisabled] = useRecoilState(customDomainFieldsDiasbled);
    const [, setvalidCostCenter] = useRecoilState(isValidCostCenter);
    const [helperMsg, setHelperMsg] = React.useState({ showHelperMsg: false, helperMsg: '' });

    const costCenterGetApi = useMutation(() => axios.get(`${endPoints.virtualService.costCenter}/${ccInputValue?.toUpperCase()}`));
    const costCenterPostApi = useMutation((payload) => axios.post(`${endPoints.virtualService.costCenter}`, payload));
    const validatecostCenterApi = useMutation(() => axios.get(`${endPoints.virtualService.costCenterValidation}/${ccInputValue?.toUpperCase()}`));
    const setCostCenter = (costCenter, costCenterDetails) => {
        setOrder({
            ...order,
            costCenter,
            costCenterDetails,
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setCostCenter(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                costCenterGetApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (costCenterGetApi?.isSuccess) {
            if (costCenterGetApi?.data?.data?.projects) {
                const combinedValue = costCenterGetApi?.data?.data?.projects.map((app) => ({ ...app, label: `${app.id}`, value: `${app.id}` }));
                setOptions(combinedValue);
            }
        }
    }, [costCenterGetApi?.isSuccess]);

    React.useEffect(() => {
        if (costCenterPostApi?.isSuccess) {
            if (costCenterPostApi?.data?.data?.projects) {
                const combinedValue = costCenterPostApi?.data?.data?.projects.map((app) => ({ ...app, label: `${app.id}`, value: `${app.id}` }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [costCenterPostApi?.isSuccess]);

    React.useEffect(() => {
        costCenterPostApi.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);
    React.useEffect(() => {
        if (validatecostCenterApi?.isError) {
            setvalidCostCenter(false);
            setHelperMsg({ showHelperMsg: true, helperMsg: validatecostCenterApi?.error?.[0] || 'budget code is notfound' });
        }
    }, [validatecostCenterApi?.isError]);
    React.useEffect(() => {
        if (validatecostCenterApi?.isSuccess) {
            setvalidCostCenter(true);
            setHelperMsg({ showHelperMsg: false, helperMsg: '' });
        }
    }, [validatecostCenterApi?.isSuccess]);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            if (value?.label) validatecostCenterApi.mutate();
            setCostCenter(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.costCenterDetails && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.costCenterDetails, order?.isTouched]);

    const getValidate = () => {
        let validateText = '';
        if (isRequired) {
            if ((!order?.costCenter && order?.isTouched && isTouched) || helperMsg?.showHelperMsg) {
                validateText = 'validate';
            } else if (!order?.costCenter) {
                validateText = 'validate1';
            }
        }
        return validateText;
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="cost-center-box">
            <Select
                key="costCenterName"
                value={order?.costCenter || null}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                }}
                disabled={!isRequired || isDisabled}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                filterOptions={(optionValues, params) => {
                    const filtered = filter(optionValues, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = optionValues.some((option) => inputValue === option.label);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            label: `"${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                onBlur={() => {
                    if (!value || !order?.costCenter) {
                        resetOptions();
                    }
                }}
                onKeyUp={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        resetOptions();
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                error={helperMsg?.showHelperMsg}
                helperText={helperMsg?.showHelperMsg && helperMsg?.helperMsg}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Budget Code (H-code, Cross Charge) *"
                        error={helperMsg?.showHelperMsg}
                        helperText={helperMsg?.helperMsg}
                        fullWidth
                    />
                )}
                sx={{ width: '100%' }}
                freeSolo
                className={getValidate()}
            />

            <InfoImg title="Budget code is required only if there is a need for certificate creation for given custom domain." />
        </Box>
    );
};

export default React.memo(CostCenter);
